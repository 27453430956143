
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'EditRemarks',
})
export default class EditRemarks extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private addPortData: any = {
        name: '',
        desc: '',
    };
    private rules: any = {
        name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        desc: [
            { required: true, message: '请输入您的描述', trigger: 'blur' },
        ],
    };
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        console.log("formData==========>", formData);
                        this.showDialog = false;
                        this.closeTodo(num); // 给父组件传值
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private afterClose() {
        this.addPortData = {
            name: '',
            desc: '',
        };
    }

}
