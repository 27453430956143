
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "CheckUserNum",
    components: {
        ContentTitle,
    },
})
export default class CheckUserNum extends Vue {
    private contentTitle: string = "用户数";
    private listTitle: string = "用户管理";
    private formInline: any = {
          option1: '选项1',
          option2: '选项1',
          option3: '选项3',
    };
    private options: any = [{
        value: '选项1',
        label: '黄金糕',
    }, {
        value: '选项2',
        label: '2',
    }, {
        value: '选项3',
        label: '3',
    }];
    private tableData: any = [{
          date: '2016-05-02',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄',
          zip: 200333,
        }, {
          date: '2016-05-04',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1517 弄',
          zip: 200333,
        }, {
          date: '2016-05-01',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1519 弄',
          zip: 200333,
        }, {
          date: '2016-05-03',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1516 弄',
          zip: 200333,
        }];
    private onSearch() {
        console.log("onSearch");
    }
    private resetForm(formName: string) {
        const formData = this.$refs[formName]! as ElForm;
        formData.resetFields();
    }
    private propGoBlack() {
        this.$router.go(-1);
    }
}
