
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'EmptySelect',
})
export default class EmptySelect extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Prop(String) private content!: string;

    private showDialog: boolean = false; // 弹窗显示与关闭
    private dialogData: any = {
        content: '',
    };

    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('content') // 监听父组件传过来的值
    private contentFun(value: string) {
        this.dialogData.content = value;
    }

    // 给父组件传值
    @Emit('tabCloseMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog() {
        this.showDialog = false;
        this.closeTodo(1);
    }
}
