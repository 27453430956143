
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import EditRemarks from "./dialog/EditRemarks.vue";
import EmptySelectDialog from "@/components/EmptySelectDialog.vue";

@Component({
    name: "CheckDbType",
    components: {
        ContentTitle,
        EditRemarks,
        EmptySelectDialog,
    },
})
export default class CheckDbType extends Vue {
    private contentTitle: string = "基本资料";
    private listTitle: string = "数据库列表";
    private emptyFlag: boolean = false;
    private content: string = "";
    private checkPwd() {
        this.emptyFlag = true;
        this.content = "MC密码：Morewis123!";
    }
    private tabCloseMsg(num: number) {
        this.emptyFlag = false;
    }
    private propGoBlack() {
        this.$router.go(-1);
    }
}
