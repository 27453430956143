
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG, SERVER_CONFIG } from '@/utils/global';

@Component({
    name: 'ResetServerDialog',
})
export default class ResetServerDialog extends Vue {
    private itemTab: any = {
        isSelect: false,
    };
    private goodsList: any = [];
    // 服务器的cpu
    private serverCpuSetList: any = [];
    private serverFreeCpu: number = 0;
    private serverCpu: number = 0;
    // 服务器的memory
    private serverMemorySetList: any = [];
    private serverFreeMemory: number = 0;
    private serverMemory: number = 0;
    // 服务器的HardDisk
    private serverHardDiskSetList: any = [];
    private serverFreeHardDisk: number = 0;
    private serverHardDisk: number = 0;
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    private serverTimeNumber: number = 0;
    // 服务器的dbType
    private serverDBType: number = 0;
    // 显示 配置项最低标准
    private serverDisabledCpu: number = 0;
    private serverDisableMemory: number = 0;
    private serverDisableHardDisk: number = 0;
    // 服务器价格
    private serverTab: any = {
        totalMoney: 0,
        payMoney: 0,
    };
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private itemData!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.goodsList.filter((item: any) => {
                if (item.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
                    this.serverCpu = item.cpu;
                    this.serverMemory = item.memory;
                    this.serverHardDisk = item.harddisk;
                    this.serverTimeNumber = item.orderDetailTimeNumber;
                    this.serverDBType = item.dbType;
                    this.getSetInfo();
                    this.startGetServerPrice();
                }
            });
            const hasAppLen = this.goodsList
                    .filter((item: any) => item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
            if (hasAppLen <= 2) {
                this.serverDisabledCpu = SERVER_CONFIG.TWO_VCUP;
                this.serverDisableMemory = SERVER_CONFIG.TWO_VCUP_MEMORY;
                this.serverDisableHardDisk = SERVER_CONFIG.TWO_VCUP_HARDDISK;
            } else if (hasAppLen > 2 && hasAppLen <= 4) {
                this.serverDisabledCpu = SERVER_CONFIG.FOUR_VCUP;
                this.serverDisableMemory = SERVER_CONFIG.FOUR_VCUP_MEMORY;
                this.serverDisableHardDisk = SERVER_CONFIG.FOUR_VCUP_HARDDISK;
            } else if (hasAppLen > 4 && hasAppLen <= 6) {
                this.serverDisabledCpu = SERVER_CONFIG.SIX_VCUP;
                this.serverDisableMemory = SERVER_CONFIG.SIX_VCUP_MEMORY;
                this.serverDisableHardDisk = SERVER_CONFIG.SIX_VCUP_HARDDISK;
            } else if (hasAppLen > 6 && hasAppLen <= 8) {
                this.serverDisabledCpu = SERVER_CONFIG.EIGHT_VCUP;
                this.serverDisableMemory = SERVER_CONFIG.EIGHT_VCUP_MEMORY;
                this.serverDisableHardDisk = SERVER_CONFIG.EIGHT_VCUP_HARDDISK;
            } else if (hasAppLen > 8 && hasAppLen <= 12) {
                this.serverDisabledCpu = SERVER_CONFIG.TWELVE_VCUP;
                this.serverDisableMemory = SERVER_CONFIG.TWELVE_VCUP_MEMORY;
                this.serverDisableHardDisk = SERVER_CONFIG.TWELVE_VCUP_HARDDISK;
            }
        }
    }
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.goodsList = [].concat(value);
    }
    private created() {
        // ...
    }
    private async getSetInfo() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置服务器的Cpu
        this.serverCpuSetList = [];
        this.serverFreeCpu =
            res.serverFreeSet.cpu > 0 ? res.serverFreeSet.cpu : res.serverCpuSetList[0].number;
        // this.serverCpu = this.serverFreeCpu;
        res.serverCpuSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeCpu) {
            this.serverCpuSetList.push(item);
            // }
        });
        // 配置服务器的Memory
        this.serverMemorySetList = [];
        this.serverFreeMemory =
            res.serverFreeSet.memory > 0 ? res.serverFreeSet.memory : res.serverMemorySetList[0].number;
        // this.serverMemory = this.serverFreeMemory;
        res.serverMemorySetList.filter((item: any) => {
            // if (item.number >= this.serverFreeMemory) {
            this.serverMemorySetList.push(item);
            // }
        });
        // 配置服务器的HardDisk
        this.serverHardDiskSetList = [];
        this.serverFreeHardDisk =
            res.serverFreeSet.hardDisk > 0 ? res.serverFreeSet.hardDisk : res.serverHardDiskSetList[0].number;
        // this.serverHardDisk = this.serverFreeHardDisk;
        res.serverHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeHardDisk) {
            this.serverHardDiskSetList.push(item);
            // }
        });
        // 配置服务器的TimeNumber
        this.serverTimeNumberSetList = [];
        this.serverFreeTimeNumber =
            res.serverFreeSet.freeTimeNumber > 0 ?
                res.serverFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        // this.serverTimeNumber = this.serverFreeTimeNumber;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.serverFreeTimeNumber) {
            this.serverTimeNumberSetList.push(item);
            // }
        });
    }
    private getServerPrice() {
        this.getPrice().then((res: any) => {
            if (res.success) {
                this.startGetServerPrice();
            }
        });
    }
    private getPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const serverGoods: any = {
            goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
            appName: '云服务器',
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: this.serverTimeNumber, // 时长
            cpu: this.serverCpu,
            memory: this.serverMemory,
            harddisk: this.serverHardDisk,
            orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
            dbType: this.serverDBType,
        };
        const isMoreDev = this.goodsList.some((el: any) => el.mcVersion === '4.0');
        if (isMoreDev) {
            serverGoods.mcVersion = '4.0';
        } else {
            serverGoods.mcVersion = '3.0';
        }
        paramData.goodsList.push(serverGoods);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.goodsList.forEach((item: any, index: number) => {
            if (item.goodsId !== 1) {
                paramData.goodsList.push(item);
            }
        });
        if (paramData.goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                resolve({success: true});
            });
        }
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price').then((res: any) => {
                if (res.success) {
                    resolve(res);
                } else {
                    // ...
                }
            });
        });
    }
    private startGetServerPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const serverGoods: any = {
            goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
            appName: '云服务器',
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: this.serverTimeNumber, // 时长
            cpu: this.serverCpu,
            memory: this.serverMemory,
            harddisk: this.serverHardDisk,
            orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
            dbType: this.serverDBType,
        };
        const isMoreDev = this.goodsList.some((el: any) => el.mcVersion === '4.0');
        if (isMoreDev) {
            serverGoods.mcVersion = '4.0';
        } else {
            serverGoods.mcVersion = '3.0';
        }
        paramData.goodsList.push(serverGoods);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck = false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.serverTab.payMoney = res.payMoney;
            this.serverTab.totalMoney = res.totalMoney;
        });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): any {
        if (num === 3) {
            const item: any = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                appName: '云服务器',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                cpu: this.serverCpu,
                memory: this.serverMemory,
                harddisk: this.serverHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                dbType: this.serverDBType,
            };
            return item;
        } else {
            return {};
        }
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
}
