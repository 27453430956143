
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'ApplicationItem',
})
export default class ApplicationItem extends Vue {
    private itemTab: any = {
        isSelect: false,
    };
    private appTimeDefaultList: any = [];
    private appTimeList: any = [];
    private priceNum = '';
    private appTime = 0;
    private discountTitle = '';
    @Prop({
        type: Array,
        default: [],
    }) private appTimeNumberSetList!: any;
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private created() {
        this.itemTab = Object.assign({}, this.itemData);
        this.appTimeDefaultList = this.appTimeNumberSetList;
        this.$nextTick(() => {
            this.appTimeList = [];
            if (this.itemTab.goodsPrice && this.itemTab.goodsPrice > 0) {
                this.priceNum = this.itemTab.goodsPrice.toFixed(2) + '/月';
            } else if (this.itemTab.goodsYearPrice && this.itemTab.goodsYearPrice > 0) {
                this.priceNum = this.itemTab.goodsYearPrice.toFixed(2) + '/年';
            } else if (this.itemTab.originalPrice >= 0) {
                this.priceNum = this.itemTab.originalPrice.toFixed(2) + '';
            } else {
                this.priceNum = Number(Number(0).toFixed(2)) + '';
            }
            // 永久价格为0的时候，不显示按月 按年
            if (this.itemTab.originalPrice && this.itemTab.originalPrice === 0) {
                // ...
            } else {
                this.appTimeDefaultList.filter((item: any) => {
                    if (this.itemTab.goodsPrice && this.itemTab.goodsPrice > 0 && item.number > 0 && item.number < 12) {
                        this.appTimeList.push(item);
                    }
                    if (this.itemTab.goodsYearPrice && this.itemTab.goodsYearPrice > 0 && item.number >= 12) {
                        this.appTimeList.push(item);
                    }
                });
            }
            if (this.itemTab.originalPrice >= 0) {
                this.appTimeList.push({
                    name: "永久",
                    number: 999,
                });
            }
            this.itemTab.orderDetailTimeNumber = this.appTimeList[0].number || 0;
            if (this.itemTab.discountList) {
                this.itemTab.discountList.forEach((item: any) => {
                    if (item.month === 999) {
                        this.discountTitle += `永久购买${item.discount}折优惠<br />`;
                    } else if (item.month >= 12) {
                        this.discountTitle += `${item.month / 12}年以上${item.discount}折优惠<br />`;
                    } else {
                        this.discountTitle += `${item.month}月以上${item.discount}折优惠<br />`;
                    }
                });
            }
        });
    }
    // 给父组件传值
    @Emit('clickSelectApplication')
    private clickSelectApplicationTodo(): object {
        return this.itemTab;
    }
    private selectApplication() {
        if (this.itemTab.isMask) {
            return;
        }
        this.itemTab.isReplace = false;
        this.clickSelectApplicationTodo();
    }
    private selectTimeNumber() {
        if (this.itemTab.orderDetailTimeNumber > 0 && this.itemTab.orderDetailTimeNumber < 12) {
            this.priceNum = this.itemTab.goodsPrice.toFixed(2) + '/月';
        } else if (this.itemTab.orderDetailTimeNumber >= 12 && this.itemTab.orderDetailTimeNumber < 999) {
            this.priceNum = this.itemTab.goodsYearPrice.toFixed(2) + '/年';
        } else if (this.itemTab.orderDetailTimeNumber === 999) {
            this.priceNum = this.itemTab.originalPrice.toFixed(2) + '';
        } else {
            this.priceNum = Number(Number(0).toFixed(2)) + '';
        }
        this.itemTab.isSelect = false;
        this.itemTab.isReplace = true;
        this.clickSelectApplicationTodo();
    }
}
