
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { moneyFormat, getDateDiffMonth, subDate, formatTime } from '@/utils/utils';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: 'RenewalUpgradeDialog',
})
export default class RenewalUpgradeDialog extends Vue {
    private title: string = '云服务器升级';
    private submitTxt = '立即升级';
    private overConfig = false; // 当前配置超过最大配置标识
    private goodsList: any = [];
    private RESOURCE_CONFIG = RESOURCE_CONFIG;
    // 是否升级状态，否则是续费
    private isUpgrade: boolean = true;
    // 是否服务器，否则是数据库
    private isServer: boolean = true;
    // cpu
    private serverCpuSetList: any = [];
    private serverCpu: any = null;
    // memory
    private serverMemorySetList: any = [];
    private serverMemory: any = null;
    // HardDisk
    private serverHardDiskSetList: any = [];
    private serverHardDisk: any = null;
    // timeNumber
    private serverTimeNumberSetList: any = [];
    private serverTimeNumber: number = 0;
    // 服务器的dbType
    private serverDBType: number = 0;
    // 显示 配置项最低标准
    private serverDisabledCpu: number = 0;
    private serverDisableMemory: number = 0;
    private serverDisableHardDisk: number = 0;
    // 商品信息
    private goodsId: unknown = null;
    private goodsInfo: any = {};
    // 服务器价格
    private serverTab: any = {
        totalMoney: 0,
        payMoney: 0,
    };
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 用户id
    @Prop({
        type: String,
        default: '',
    }) private serverId!: string; // 服务器id
    @Prop({
        type: Number,
        default: null,
    }) private dbId!: number; // 数据库id
    @Prop({
        type: Number,
        default: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE, // RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW
    }) private orderGoodsSceneType!: number; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('orderGoodsSceneType')
    private initType(val: number) {
        if (val === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW) {  // 服务器续费
            this.isUpgrade = false;
            this.isServer = true;
            this.title = '服务器续费';
            this.submitTxt = '立即续费';
        } else if (val === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE) {  // 服务器升级
            this.isUpgrade = true;
            this.isServer = true;
            this.title = '服务器升级';
            this.submitTxt = '立即升级';
        } else if (val === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW) {  // 数据库续费
            this.isUpgrade = false;
            this.isServer = false;
            this.title = 'Oracle数据库续费';
            this.submitTxt = '立即续费';
        } else if (val === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_UPGRADE) {  // 数据库升级
            this.isUpgrade = true;
            this.isServer = false;
            this.title = 'Oracle数据库升级';
            this.submitTxt = '立即升级';
        }
    }
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            if (this.orderGoodsSceneType === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW    // 服务器续费
            || this.orderGoodsSceneType === RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE) {  // 服务器升级
                this.goodsId = RESOURCE_CONFIG.SERVER_GOODSID;
                this.getServerInfo();
            } else if (this.orderGoodsSceneType === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW // 数据库续费
            || this.orderGoodsSceneType === RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_UPGRADE) {  // 数据库升级
                this.goodsId = RESOURCE_CONFIG.ORACLE_GOODSID;
                this.getDBInfo();
            }
        }
    }
    private getMonthBetween(endDate: any) {
        const startDate = new Date();
        endDate = new Date(endDate.replace(/-/g, '/'));
        let num = 0;
        const year = endDate.getFullYear() - startDate.getFullYear();
        num += year * 12;
        const month = endDate.getMonth() - startDate.getMonth();
        num += month;
        const day = endDate.getDate() - startDate.getDate();
        if (day > 0) {
            num += 1;
        }
        return num;
    }
    private async getSetInfo() {
        this.overConfig = false;
        const paramData: any = {};
        paramData.preventRepeat = 'orderConfirm';
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            if (this.isServer) {
                // 配置服务器的Cpu
                this.serverCpuSetList = res.serverCpuSetList;
                // 配置服务器的Memory
                this.serverMemorySetList = res.serverMemorySetList;
                // 配置服务器的HardDisk
                this.serverHardDiskSetList = res.serverHardDiskSetList;
            } else {
                // 配置数据库的Cpu
                this.serverCpuSetList = res.dbCpuSetList;
                // 配置数据库的Memory
                this.serverMemorySetList = res.dbMemorySetList;
                // 配置数据库的HardDisk
                this.serverHardDiskSetList = res.dbHardDiskSetList;
            }
            // 配置服务器的TimeNumber
            let serverTimeNumber = this.getMonthBetween(this.goodsInfo.expireAt);
            this.serverTimeNumberSetList = [];
            let diffMonth = 0;
            let isSetTimeNumber = false;
            if (this.goodsInfo.expireAt) {
                diffMonth = getDateDiffMonth(this.goodsInfo.expireAt);
            }
            let i = 0;
            const all: any = [[]];
            res.timeNumberSetList.map((item: any, index: number) => {
                if (index >= (i + 1) * 8) {
                    all.push([]);
                    i = i + 1;
                }
                all[i].push({
                    ...item,
                    disabled: item.number < diffMonth,
                });
                // 续费时，判断到期时间是否过去；如过期购买时长需满足购买后的到期时间大于当前时间
                if (!this.isUpgrade
                && item.number >= diffMonth
                && !isSetTimeNumber) {
                    isSetTimeNumber = true;
                    serverTimeNumber = item.number;
                }
            });
            this.serverTimeNumber = serverTimeNumber;
            this.serverTimeNumberSetList = all;
            // 升级时，判断服务器配置是否是最大配置
            if (this.isUpgrade
            && this.goodsInfo.cpu >= this.serverCpuSetList[this.serverCpuSetList.length - 1].number
            && this.goodsInfo.memory >= this.serverMemorySetList[this.serverMemorySetList.length - 1].number
            && this.goodsInfo.hardDisk >= this.serverHardDiskSetList[this.serverHardDiskSetList.length - 1].number) {
                this.overConfig = true;
            }
            // 续费时，判断过期时间是否太久
            if (!this.isUpgrade && !isSetTimeNumber) {
                this.overConfig = true;
            }
            if (!this.overConfig) {
                this.getServerPrice();
            }
        });
    }
    // 获取服务器信息
    private getServerInfo() {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server')
        .then((res: any) => {
            console.log(res);
            this.goodsInfo = res;
            this.serverCpu = res.cpu;
            this.serverMemory = res.memory;
            this.serverHardDisk = res.storage;
            // this.serverTimeNumber = this.getMonthBetween(res.expireAt);
            this.serverDisabledCpu = res.cpu;
            this.serverDisableMemory = res.memory;
            this.serverDisableHardDisk = res.storage;
            this.getSetInfo();
        });
    }
    // 获取数据库信息
    private getDBInfo() {
        this.$httpService.getData({}, `/apiProxy/api/frontend/user/server/db/${this.dbId}/info`)
        .then((res: any) => {
            console.log(res);
            this.goodsInfo = res;
            this.serverCpu = res.cpu;
            this.serverMemory = res.memory;
            this.serverHardDisk = res.hardDisk;
            // this.serverTimeNumber = this.getMonthBetween(res.expireAt);
            this.serverDisabledCpu = res.cpu;
            this.serverDisableMemory = res.memory;
            this.serverDisableHardDisk = res.hardDisk;
            this.getSetInfo();
        });
    }
    private getServerGoods() {
        return {
            goodsId: this.goodsId, // 选中商品传给后端的值
            // appName: '云服务器',
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: this.serverTimeNumber, // 时长
            cpu: this.serverCpu,
            memory: this.serverMemory,
            harddisk: this.serverHardDisk,
            orderGoodsSceneType: this.orderGoodsSceneType,
            dbType: this.goodsInfo.dbType || this.serverDBType,
            dbId: this.dbId,
            serverId: this.serverId || this.goodsInfo.serverId,
        };
    }
    private getServerPrice() {
        // 升级时，判断是否选择更高配置
        if (this.isUpgrade
        && this.serverCpu <= this.serverDisabledCpu
        && this.serverMemory <= this.serverDisableMemory
        && this.serverHardDisk <= this.serverDisableHardDisk) {
            this.serverTab.payMoney = 0;
            this.serverTab.totalMoney = 0;
        } else {
            this.getPrice();
        }
    }
    private getPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const serverGoods: any = this.getServerGoods();
        paramData.goodsList.push(serverGoods);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck = false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.serverTab.payMoney = res.payMoney;
            this.serverTab.totalMoney = res.totalMoney;
        });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        // 升级时，判断是否选择更高配置
        if (this.isUpgrade
        && this.serverCpu <= this.serverDisabledCpu
        && this.serverMemory <= this.serverDisableMemory
        && this.serverHardDisk <= this.serverDisableHardDisk) {
            this.$message.warning('请选择要升级的配置');
            return;
        }
        const goodsList = [this.getServerGoods()];
        if (this.isUpgrade) {
            this.$router
            .push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}&userData=${this.userId}`)
            .catch((err) => err);
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.checkDbEndTime(goodsList, num);
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): any {
        return num;
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
    private checkDbEndTime(goodsList: any, num: number) {
        const paramData: any = {};
        if (this.userId !== '') {
            paramData.userId = this.userId;
        }
        paramData.serverId = this.serverId;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/goods/server/db/expire`)
        .then((res: any) => {
            const serveEndTime = subDate(new Date(this.goodsInfo.expireAt), this.serverTimeNumber, 'M', 'yyyy-MM-dd hh:mm:ss');
            const goodsListCopy = JSON.parse(JSON.stringify(goodsList));
            res.forEach((item: any) => {
                // -1，待部署；-2，已卸载；-3，已拒绝；
                if (item.dbRunType !== -1 && item.dbRunType !== -2 && item.dbRunType !== -3
                    && item.dbType === 2
                    && item.dbEndTime
                    && item.dbEndTime !== ''
                    && new Date(serveEndTime) > new Date(item.dbEndTime)) {
                    const serveEndTimestamp = Date.parse(formatTime(new Date(serveEndTime) + '', 'yyyy-MM-dd'));
                    const dbEndTimeTimestamp = Date.parse(formatTime(item.dbEndTime, 'yyyy-MM-dd'));
                    let dbTimeNumber = this.serverTimeNumber;
                    const days = (serveEndTimestamp - dbEndTimeTimestamp) / (1 * 24 * 60 * 60 * 1000);
                    if (days > 365 * 5) {
                        // this.$message.warning('您的数据库已经过期过久了，请联系管理员！');
                        dbTimeNumber = 12 * 5;
                    } else {
                        const divisionYearNum = Math.ceil(days / 365);
                        const divisionMonthNum = Math.ceil(days / 30);
                        if (divisionYearNum > 1) {
                            dbTimeNumber = divisionYearNum * 12;
                        } else {
                            dbTimeNumber = divisionMonthNum;
                        }
                    }
                    // 数据库续费信息
                    const dbItem: any = {
                        cpu: item.dbCpu,
                        dbId: item.dbId,
                        goodsId: 3,
                        harddisk: item.dbHarddisk,
                        memory: item.dbMemory,
                        orderDetailNumber: 1, // 选中商品数量(固定不变的)
                        orderDetailTimeNumber: dbTimeNumber,
                        orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW,
                        serverId: this.serverId,
                    };
                    goodsListCopy.push(dbItem);
                }
            });
            this.$router
                .push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsListCopy)}&userData=${this.userId}`)
                .catch((err) => err);
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        });
    }
}
