
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: 'ResetOracleDialog',
})
export default class ResetOracleDialog extends Vue {
    private goodsList: any = [];
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 数据库数量
    private orderDetailNumber: number = 0;
    // 服务器价格
    private priceTab: any = {
        totalMoney: 0,
        payMoney: 0,
    };
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private itemData!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.goodsList.filter((item: any) => {
                if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                    this.dbCpu = item.cpu;
                    this.dbMemory = item.memory;
                    this.dbHardDisk = item.harddisk;
                    this.dbTimeNumber = item.orderDetailTimeNumber;
                    this.orderDetailNumber = item.orderDetailNumber;
                    this.getDBTab();
                    this.startGetPrice();
                }
            });
        }
    }
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.goodsList = [].concat(value);
    }
    private created() {
        // ...
    }
    // 组装数据库配置
    private getDBTab() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置数据库的Cpu
        this.dbCpuSetList = [];
        this.dbFreeCpu =
            res.dbFreeSet.cpu > 0 ? res.dbFreeSet.cpu : res.dbCpuSetList[0].number;
        // this.dbCpu = this.dbFreeCpu;
        res.dbCpuSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeCpu) {
                this.dbCpuSetList.push(item);
            // }
        });
        // 配置数据库的Memory
        this.dbMemorySetList = [];
        this.dbFreeMemory =
            res.dbFreeSet.memory > 0 ? res.dbFreeSet.memory : res.dbMemorySetList[0].number;
        // this.dbMemory = this.dbFreeMemory;
        res.dbMemorySetList.filter((item: any) => {
            // if (item.number >= this.dbFreeMemory) {
                this.dbMemorySetList.push(item);
            // }
        });
        // 配置数据库的HardDisk
        this.dbHardDiskSetList = [];
        this.dbFreeHardDisk =
            res.dbFreeSet.hardDisk > 0 ? res.dbFreeSet.hardDisk : res.dbHardDiskSetList[0].number;
        // this.dbHardDisk = this.dbFreeHardDisk;
        res.dbHardDiskSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeHardDisk) {
                this.dbHardDiskSetList.push(item);
            // }
        });
        // 配置数据库的TimeNumber
        this.dbTimeNumberSetList = [];
        this.dbFreeTimeNumber =
            res.dbFreeSet.freeTimeNumber > 0 ? res.dbFreeSet.freeTimeNumber : res.timeNumberSetList[0].number;
        // this.dbTimeNumber = this.dbFreeTimeNumber;
        res.timeNumberSetList.filter((item: any) => {
            // if (item.number >= this.dbFreeTimeNumber) {
            this.dbTimeNumberSetList.push(item);
            // }
        });
    }
    private getPrice() {
        this.startGetPrice();
    }
    private startGetPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const oracleGoods = {
            goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
            appName: 'Oracle',
            orderDetailNumber: this.orderDetailNumber, // 选中商品数量(累加)
            orderDetailTimeNumber: this.dbTimeNumber, // 时长
            cpu: this.dbCpu,
            memory: this.dbMemory,
            harddisk: this.dbHardDisk,
            orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
        };
        paramData.goodsList.push(oracleGoods);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck = false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.priceTab.payMoney = res.payMoney;
            this.priceTab.totalMoney = res.totalMoney;
        });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): any {
        if (num === 3) {
            const oracleGoods = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: this.orderDetailNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: this.dbTimeNumber, // 时长
                cpu: this.dbCpu,
                memory: this.dbMemory,
                harddisk: this.dbHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            return oracleGoods;
        } else {
            return {};
        }
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
}
