
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import ApplicationItem from "./component/ApplicationItem.vue";
import ServiceDialog from "./component/ServiceDialog.vue";
import ChooseDialog from "./component/ChooseDialog.vue";
import ServiceItem from "./component/ServiceItem.vue";
import ChooseBuyItem from "./component/ChooseBuyItem.vue";
import InitServerOracle from "./component/InitServerOracle.vue";
import ResetServerDialog from "./component/ResetServerDialog.vue";
import ResetOracleDialog from "./component/ResetOracleDialog.vue";
import ResetUserNumberDialog from "./component/ResetUserNumberDialog.vue";
import SelectProductList from "./component/SelectProductList.vue";
import ErrorHttpDialog from "./component/ErrorHttpDialog.vue";
import RenewalUpgradeDialog from '../component/dialog/RenewalUpgradeDialog.vue';
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG, SERVER_CONFIG, SERVER_CONFIG_MC_FOUR } from '@/utils/global';
import { fromCode, toCode } from '@/utils/encrypt';

@Component({
    name: "resourceBuy",
    components: {
        ContentTitle,
        ApplicationItem,
        ServiceItem,
        ServiceDialog,
        ChooseBuyItem,
        ChooseDialog,
        InitServerOracle,
        ResetServerDialog,
        ResetOracleDialog,
        ResetUserNumberDialog,
        SelectProductList,
        ErrorHttpDialog,
        RenewalUpgradeDialog,
    },
})
export default class ResourceBuy extends Vue {
    private contentTitle: string = "创建订单";
    private goBackFlag: boolean = true; // 是否显示返回按钮
    private SERVER_CONFIG: any = SERVER_CONFIG;
    private SERVER_CONFIG_MC_FOUR: any = SERVER_CONFIG_MC_FOUR;
    private surplusChooseNum: number = 0;
    private surplusChooseNumFourVersion: number = 0; // 4.0版本剩余多少配置
    private anchorIndex: number = 1; // 1、SaaS；2、应用；3、服务
    private productData: any = {
        pageNo: 1,
        pageSize: 8,
        productName: '',
    };
    private chooseFlag: boolean = false;
    private serviceList: any = [];
    private serviceItemData: any = {};
    // url带的userId
    private userId: string = '';
    private helpTitle: string = '';
    // 服务器的cpu
    private serverCpuSetList: any = [];
    private serverFreeCpu: number = 0;
    private serverCpu: number = 0;
    // 服务器的memory
    private serverMemorySetList: any = [];
    private serverFreeMemory: number = 0;
    private serverMemory: number = 0;
    // 服务器的HardDisk
    private serverHardDiskSetList: any = [];
    private serverFreeHardDisk: number = 0;
    private serverHardDisk: number = 0;
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    private serverTimeNumber: number = 0;
    // 服务器需要的dbType
    private serverDBType: number = 0;
    // 数据库的cpu
    private dbCpuSetList: any = [];
    private dbFreeCpu: number = 0;
    private dbCpu: number = 0;
    // 数据库的memory
    private dbMemorySetList: any = [];
    private dbFreeMemory: number = 0;
    private dbMemory: number = 0;
    // 数据库的HardDisk
    private dbHardDiskSetList: any = [];
    private dbFreeHardDisk: number = 0;
    private dbHardDisk: number = 0;
    // 数据库的timeNumber
    private dbTimeNumberSetList: any = [];
    private dbFreeTimeNumber: number = 0;
    private dbTimeNumber: number = 0;
    // 服务器的授权用户数
    private userNumber: number = 0;
    // 产品默认时间列表
    private appTimeNumberSetList: any = [];
    // 用户公司3.0服务器信息
    private companyServerObj: any = {};
    // 用户公司4.0服务器信息
    // private companyServerFourObj: any = {};
    private companyServerFourList: any = [];
    // SaaS产品列表
    private productSaaSList: any = [];
    private productSaaSTotal: number = 0;
    // app产品列表
    private productAppList: any = [];
    private productAppTotal: number = 0;
    // 加载
    private loading: boolean = true;
    private disabledScroll: boolean = false;
    private disabledSaasScroll: boolean = false;
    // 选购的产品列表
    private selectProductList: any = [];
    // 选购产品的总数
    private selectedProductNum: number = 0;
    // 已选的数据库
    private selectedDBData: any = {};
    // 所有商品价格
    private allProductTab: any = {
        serverMoney: 0,
        dbMoney: 0,
        discountMoney: 0,
        totalMoney: 0,
    };
    // 服务器免费配置
    private serverFreeTab: any = {};
    private serverFreeFourTab: any = {};
    private isInitServerOracle: boolean = false; // 初次显示服务器或者数据库悬浮框
    private resetServerFlag: boolean = false; // 调整服务器配置
    private resetOracleFlag: boolean = false; // 调整Oracle配置
    private resetUserNumberFlag: boolean = false; // 调整云用户数配置
    // 资源不足提示
    private errorFlag: boolean = false;
    private errorMessage: string = '';
    // 记录错误弹框 是3.0还是4.0应用
    private mcVersion: number = 3;
    private isSelectProductList: boolean = false; // 是否显示选购产品明细
    private contentBox: any = null;
    // 服务器升级、续费
    private serverId: string = '';
    private renewalUpgradFlag: boolean = false;
    private orderGoodsSceneType: number = RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE;
    // 4.0云服务器配置
    private serverIdMoreDev: string = '-1';
    private serverFourList: any = [];
    // 4.0试用服务器参数
    private companyServerFourTrialObj: any = {};
    @Watch('selectProductList', {deep: true})
    private selectProductListChanged(newValue: any, oldValue: any) {
        this.selectedProductNum = this.selectProductList
        .reduce((pre: any, item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID) {
                return pre + item.userNumber;
            } else {
                return pre + item.orderDetailNumber;
            }
        }, 0);
        // 检查已选中产品是否有第一次添加服务器或者数据库，如果有则显示服务器或者数据库悬浮框
        const oldValueServerLen = oldValue
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID).length;
        const oldValueOracle = oldValue
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        const newValueServerLen = newValue
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID).length;
        const newValueOracle = newValue
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        if (oldValueServerLen === 0 && newValueServerLen > 0) {
            this.isInitServerOracle = true;
        }
        if (oldValueOracle === 0 && newValueOracle > 0) {
            this.isInitServerOracle = true;
        }
        if (newValueServerLen === 0 && newValueOracle === 0) {
            this.isInitServerOracle = false;
        }
    }
    private async created() {
        if (this.$route.fullPath.indexOf('userData=') > -1) {
            this.userId = fromCode(this.$route.query.userData + '');
            this.contentTitle = this.userId !== '' ? '代客下单' : '创建订单';
        }
        this.disabledScroll = true;
        this.disabledSaasScroll = true;
        await this.getCompanyServer().then(() => {
            this.initProduct();
        });
        await this.getSetInfo();
    }
    private async initProduct() {
        this.loading = true;
        this.disabledScroll = true;
        this.disabledSaasScroll = true;
        this.selectProductList = [];
        this.productData.pageNo = 1;
        this.anchorIndex = 1;
        await this.getSaaSList(this.productData.pageNo);
        await this.getAppList(this.productData.pageNo);
        await this.getServiceList();
        // this.$nextTick(() => {
        //     this.loading = false;
        // });
    }
    private async getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            // 得到免费服务器的授权用户数
            res.userLimitList.filter((item: any) => {
                if (item.price === 0) {
                    this.userNumber = item.number;
                }
            });
            // 获得3.0服务器的免费配置
            this.serverFreeTab = res.serverFreeSet;
            // 获得4.0服务器的免费配置
            this.serverFreeFourTab = res.serverFreeSet4;
        });
    }
    // 获得个人的 服务器列表
    private async getCompanyServer() {
        const paramUserId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        return new Promise((resolve, reject) => {
            this.$httpService.getData({}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
            .then((res: any) => {
                this.companyServerObj = {};
                // 3.0正式
                const hasMCThird = res.serverInfoList
                    .some((item: any) => item.serverType  === SERVER_CONFIG.SERVER_TYPE);
                const objMCThird = res.serverInfoList
                    .find((item: any) => item.serverType  === SERVER_CONFIG.SERVER_TYPE);
                if (hasMCThird && objMCThird.serverCpu
                    && objMCThird.serverHarddisk
                    && objMCThird.serverMemory ) {
                    this.companyServerObj = {
                        serverId: objMCThird.serverId,
                        serverName: objMCThird.serverName,
                        serverExpired: objMCThird.serverExpired,
                        serverType: objMCThird.serverType,
                        installStatus: objMCThird.installStatus, // -1,安装失败；1，待安装；2，安装中；3，已安装
                        userSysTypeInfoList: objMCThird.userSysTypeInfoList,
                    };
                    const appLength = objMCThird.userSysTypeInfoList
                        .filter((el: any) => el.usageType === 3 || el.usageType === 1).length;
                    const configNum = this.computeConfigNum(objMCThird);
                    this.surplusChooseNum = configNum - appLength;
                    console.log('surplusChooseNum', this.surplusChooseNum);
                    if (this.surplusChooseNum < 0) {
                        this.surplusChooseNum = 0;
                    }
                }
                // 4.0正式
                this.companyServerFourList = [];
                res.serverInfoList.forEach((item: any) => {
                    if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                        const appLength = item.userSysTypeInfoList
                        .filter((el: any) => el.usageType === 3 || el.usageType === 1).length;
                        const configNum = this.computeConfigNumFourVersion(item);
                        const num = configNum - appLength;
                        const obj = {
                            serverId: item.serverId,
                            serverName: item.serverName,
                            serverExpired: item.serverExpired,
                            serverType: item.serverType,
                            installStatus: item.installStatus, // -1,安装失败；1，待安装；2，安装中；3，已安装
                            userSysTypeInfoList: item.userSysTypeInfoList,
                            surplusChooseNumFourVersion: num > 0 ? num : 0,
                        };
                        this.companyServerFourList.push(obj);
                    }
                });
                if (this.userId !== '') {
                    this.helpTitle = `归属客户：${res.companyName}`;
                }
                if (this.userId === ''
                    && (!this.companyServerObj.serverType && this.companyServerFourList.length === 0)) {
                    this.goBackFlag = false;
                }
                this.serverFourList = [];
                res.serverInfoList.forEach((item: any) => {
                    if (item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) { // SERVER_CONFIG_MC_FOUR.SERVER_TYPE
                        item.serverId = item.serverId.toString();
                        this.serverFourList.push(item);
                    }
                });
                if (this.serverFourList.length > 0) {
                    this.serverIdMoreDev = this.serverFourList[0].serverId;
                } else {
                    this.serverIdMoreDev = '-1';
                }
                const objMCFourTrial = res.serverInfoList
                        .find((item: any) => item.serverType  === SERVER_CONFIG_MC_FOUR.SERVER_TYPE_TRIAL);
                this.companyServerFourTrialObj = Object.assign({}, objMCFourTrial);
                resolve(res);
            });
        });
    }
    // mc3.0计算应用配置
    private computeConfigNum(obj: any) {
        let configNum = 0;
        // 每两个应用加2核/3G/30G 就是按 1核/1.5G/15G 的规则来计算
        if (obj.serverCpu >= 0 && obj.serverHarddisk >= 0 && obj.serverMemory >= 0) {
            const serverCpuNum = obj.serverCpu; // 3.0 理论上多少核就可以配合搭配多少个应用
            const serverHarddiskNum = Math.floor(obj.serverHarddisk / 15);
            const serverMemoryNum = Math.floor(obj.serverMemory / 1.5);
            configNum = Math.min(serverCpuNum, serverHarddiskNum, serverMemoryNum);
        }
        return configNum;
    }
    // mc4.0计算应用配置
    private computeConfigNumFourVersion(obj: any) {
        let configNum = 0;
        if (obj.serverCpu >= 4 && obj.serverHarddisk >= 30 && obj.serverMemory >= 16) {
            const serverCpuNum = (obj.serverCpu - 4) + 2; // 4.0 第一个4G 才算1个应用的配置
            const serverHarddiskNum = Math.floor((obj.serverHarddisk - 60) / 10) + 2;
            const serverMemoryNum = Math.floor((obj.serverMemory - 16) / 4) + 2;
            configNum = Math.min(serverCpuNum, serverHarddiskNum, serverMemoryNum);
        }
        return configNum;
    }
    private async getServiceList() {
        // 得到服务商品列表
        const paramData: any = {};
        paramData.pageNo = 1;
        paramData.pageSize = 200;
        if (this.productData.productName !== '') {
            paramData.name = this.productData.productName;
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/service')
        .then((res: any) => {
            this.serviceList = [];
            res.list.filter((item: any) => {
                const selectedLen = this.selectProductList
                    .filter((el: any) => el.goodsId === item.id).length;
                item.isSelect = selectedLen > 0 ? true : false;
                item.orderDetailTimeNumber = 1;
                this.serviceList.push(item);
            });
        });
    }
    private async getSaaSList(pageIndex: number) {
        this.disabledSaasScroll = true;
        if (this.productSaaSTotal > 0 && (this.productSaaSTotal / 8  - pageIndex) < -1 ) {
            this.disabledSaasScroll = true;
            return;
        }
        // 得到SAAS 商品列表
        const paramData: any = {};
        paramData.pageNo = pageIndex;
        paramData.pageSize = 200;
        paramData.goodsType = 7;
        if (this.productData.productName !== '') {
            paramData.name = this.productData.productName;
        }
        if (pageIndex === 1) {
            this.productSaaSList = [];
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/saas-app')
        .then((res: any) => {
            this.disabledSaasScroll = false;
            // this.productSaaSList = this.productSaaSList.concat(res.list);
            res.list.filter((item: any) => {
                const selectedLen = this.selectProductList
                    .filter((el: any) => el.goodsId === item.id).length;
                item.isSelect = selectedLen > 0 ? true : false;
                item.orderDetailTimeNumber = 0;
                this.productSaaSList.push(item);
            });
            this.productSaaSTotal = res.total;
        });
    }
    private async getAppList(pageIndex: number) {
        this.disabledScroll = true;
        this.loading = true;
        if (this.productAppTotal > 0 && (this.productAppTotal / 8 - pageIndex) < -1 ) {
            this.disabledScroll = true;
            return;
        }
        // 得到应用 商品列表
        const paramData: any = {};
        paramData.pageNo = pageIndex;
        paramData.pageSize = 200;
        paramData.goodsType = 6;
        if (this.productData.productName !== '') {
            paramData.name = this.productData.productName;
        }
        if (pageIndex === 1) {
            this.productAppList = [];
        } else {
            paramData.notLoading = true;
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/saas-app')
        .then((res: any) => {
            this.disabledScroll = false;
            this.loading = false;
            res.list.filter((item: any) => {
                const selectedLen = this.selectProductList
                    .filter((el: any) => el.goodsId === item.id).length;
                item.isSelect = selectedLen > 0 ? true : false;
                item.orderDetailTimeNumber = 0;
                // 3.0 产品遮罩层判断, 服务器过期
                if ( item.mcVersion === '3.0'
                && this.companyServerObj
                && this.companyServerObj.serverExpired
                && this.companyServerObj.serverType === SERVER_CONFIG.SERVER_TYPE) {
                    item.isMask = true;
                }
                // 4.0 产品遮罩层判断, 服务器过期，关闭掉吧，不然切换的时候，还需要去刷新一次
                // if ( item.mcVersion === '4.0'
                // && this.companyServerFourObj
                // && this.companyServerFourObj.serverExpired
                // && this.companyServerFourObj.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                //     item.isMask = true;
                // }
                // 过滤 MoreDev 平台
                // const isMoreDev = item.mcVersion === '4.0' && item.id === RESOURCE_CONFIG.MOREDEV_GOODSID;
                // if (!isMoreDev) {
                //     this.productAppList.push(item);
                // }
                this.productAppList.push(item);
            });
            this.productAppTotal = res.total;
        });
    }
    private async searchHandle() {
        this.anchorIndex = 1;
        await this.getSaaSList(this.productData.pageNo);
        await this.getAppList(this.productData.pageNo);
        await this.getServiceList();
    }
    // 接收子页面传回来的值
    private propGoBlack() {
        if (this.userId !== '') {
            // this.$router
            // .push(`/company/reportCustomer`)
            // .catch((err) => err);
            this.$router.go(-1);
        } else {
            window.history.back();
        }
    }
    private propBuyService(value: object) {
        this.serviceItemData = value;
        this.chooseFlag = true;
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
    // 立即购买
    private buyProduct() {
        if (this.selectProductList.length === 0) {
            this.$message.warning('请至少选购一个产品');
            return;
        }
        // /company/orderConfirm?goodsData=${JSON.stringify(this.selectProductList)}&userData=${this.userId}`
        this.$router
        .push({
            path: '/company/orderConfirm',
            query: {
                goodsData: JSON.stringify(this.selectProductList),
                userData: this.userId,
            },
        })
        .catch((err) => err);
    }
    // 点击选购按钮
    private propBuyChoose(value: any) {
        this.chooseStartValidate(value);
    }
    // 选购前先统一验证数量和数据库
    private chooseStartValidate(item: any) {
        console.log('this.selectProductList', this.selectProductList);
        const selectedOracleLen = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID).length;
        const selectedAppLen = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        const selectedThirdAppLen = this.selectProductList
                .filter((el: any) => el.mcVersion === '3.0').length;
        const selectedFourAppLen = this.selectProductList
                .filter((el: any) => el.mcVersion === '4.0').length;
        // 已选购产品中 是否 包含授权用户数
        const selectedGrantList = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        // 已选购买断提示，只能添加服务
        if (selectedGrantList.length > 0 && item.goodsPriceType !== RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
            // 提示
            this.$confirm(`您所选择的商品中“买断”类型的应用，只能和服务类商品同时购买，不能和其它类型商品同时购买，请分开下单！`, '提示', {
                confirmButtonText: '去移除',
                cancelButtonText: '我知道了',
                cancelButtonClass: 'remove_cancel_class',
                confirmButtonClass: 'remove_confirm_class',
            }).then(() => {
                // 确定
                this.propHandleGotoRemove();
            }).catch(() => {
                // 取消
            });
            return;
        }
        // 新旧版本提示
        if (
            (selectedThirdAppLen > 0 && item.mcVersion === '4.0')
            ||
            (selectedFourAppLen > 0 && item.mcVersion === '3.0')
            ) {
            // 提示
            this.$confirm(`您所选择的商品中包含新旧版本应用，不能同时购买，请分开下单！`, '新旧版本应用', {
                confirmButtonText: '去移除',
                cancelButtonText: '我知道了',
                cancelButtonClass: 'remove_cancel_class',
                confirmButtonClass: 'remove_confirm_class',
            }).then(() => {
                // 确定
                this.propHandleGotoRemove();
            }).catch(() => {
                // 取消
            });
            return;
        }
        // 已选数据库,产品只支持另一种数据库判断
        if (
            (
                selectedOracleLen > 0
                && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
                && item.dbTypes && item.dbTypes.indexOf('2') === -1
            )
            ||
            (
                selectedOracleLen === 0 && selectedAppLen > 0
                && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
                && item.dbTypes && item.dbTypes.indexOf('1') === -1
            )
            ) {
            this.$confirm(`暂不支持同时下单购买不同数据库类型的应用，如有需要，请分开下单；如本次只想购买该数据库类型应用可以将已选的应用移除后再来操作；`, '数据库类型不一致', {
                confirmButtonText: '去移除',
                cancelButtonText: '我知道了',
                cancelButtonClass: 'remove_cancel_class',
                confirmButtonClass: 'remove_confirm_class',
            }).then(() => {
                // 确定
                this.propHandleGotoRemove();
            }).catch(() => {
                // 取消
            });
            return;
        }
        // 3.0应用购买已达上限，不允许继续选购应用产品，selectedAppLen 只能全部3.0或者全部4.0
        let userSysTypeLen = this.companyServerObj.userSysTypeInfoList
            ? this.companyServerObj.userSysTypeInfoList.length : 0;
        if (this.companyServerObj.userSysTypeInfoList) {
            this.selectProductList.forEach((el: any) => {
                if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                    const userSysTypeInfoListLen = this.companyServerObj.userSysTypeInfoList
                        .filter((elItem: any) => elItem.goodsId === el.goodsId).length;
                    userSysTypeLen -= userSysTypeInfoListLen;
                    if (selectedOracleLen > 0 && userSysTypeInfoListLen > 0) {
                        userSysTypeLen += 1;
                    }
                }
            });
        }
        const hasAppLen = selectedAppLen + userSysTypeLen;
        if (item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
        && this.companyServerObj
        && (!this.companyServerObj.serverType || this.companyServerObj.serverType !== SERVER_CONFIG.SERVER_TYPE)
        && hasAppLen >= 12
        ) {
            const msg = `云服务器${this.companyServerObj.serverName}应用购买已达上限，不允许继续选购应用产品`;
            this.$alert(msg, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        // 4.0应用购买已达上限，不允许继续选购应用产品
        let userSysTypeLenFour = this.companyServerFourObj.userSysTypeInfoList
            ? this.companyServerFourObj.userSysTypeInfoList.length : 0;
        if (this.companyServerFourObj.userSysTypeInfoList) {
            this.selectProductList.forEach((el: any) => {
                if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                    const userSysTypeInfoListLen = this.companyServerFourObj.userSysTypeInfoList
                        .filter((elItem: any) => elItem.goodsId === el.goodsId).length;
                    userSysTypeLenFour -= userSysTypeInfoListLen;
                    if (selectedOracleLen > 0 && userSysTypeInfoListLen > 0) {
                        userSysTypeLenFour += 1;
                    }
                }
            });
        }
        const hasAppLenFour = selectedAppLen + userSysTypeLenFour;
        if (item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE
        && this.companyServerFourObj
        && (!this.companyServerFourObj.serverType
            ||
            this.companyServerFourObj.serverType !== SERVER_CONFIG_MC_FOUR.SERVER_TYPE)
        && hasAppLenFour > 5
        ) {
            const msg = `云服务器${this.companyServerFourObj.serverName || '4.0新开服务器'}应用购买已达上限，不允许继续选购应用产品`;
            this.$alert(msg, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        // end
        this.selectedDBData = {
            appName: '',
        };
        // 已选了oracle
        if (selectedOracleLen > 0 && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            this.selectedDBData = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID)[0];
        }
        // 已选pg
        if (selectedOracleLen === 0 && selectedAppLen > 0
            && item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            this.selectedDBData = {
                appName: 'PostgreSQL',
            };
        }
        this.serviceItemData = item;
        this.chooseFlag = true;
    }
    // 选购成功开始接口认证
    private propChooseClose(value: any) {
        this.chooseFlag = false;
        if (value.length === 0) {
            return;
        }
        // 选购，是否有 授权用户数=买断
        const valueGrantList = value.filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        // 已选购 除了 服务还有多少
        const selectedNoServiceList = this.selectProductList
                .filter((el: any) => el.orderGoodsSceneType !== RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE);
        // 如果是先买服务，就还可以买断，其它不能
        if (selectedNoServiceList.length > 0 && valueGrantList.length > 0) {
            // 提示
            this.$confirm(`您所选择的商品中“买断”类型的应用，只能和服务类商品同时购买，不能和其它类型商品同时购买，请分开下单！`, '提示', {
                confirmButtonText: '去移除',
                cancelButtonText: '我知道了',
                cancelButtonClass: 'remove_cancel_class',
                confirmButtonClass: 'remove_confirm_class',
            }).then(() => {
                // 确定
                this.propHandleGotoRemove();
            }).catch(() => {
                // 取消
            });
            return;
        }
        this.getChoosePrice(value, true).then(() => {
            value.forEach((item: any) => {
                if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                    this.productAppList.forEach((appItem: any) => {
                        // 20230721
                        // 当已选中的产品有4.0应用，开发平台也要一起 设置成选中状态
                        this.selectProductList.forEach((selectItem: any) => {
                            if (appItem.id === selectItem.goodsId) {
                                appItem.isSelect = true;
                            }
                        });
                    });
                } else if (item.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE
                || item.orderGoodsSceneType === RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW) {
                    this.productSaaSList.forEach((appItem: any) => {
                        if (appItem.id === item.goodsId) {
                            appItem.isSelect = true;
                        }
                    });
                } else if (item.orderGoodsSceneType === RESOURCE_CONFIG.SERVICE_GOODSSCENETYPE) {
                    this.serviceList.forEach((appItem: any) => {
                        if (appItem.id === item.goodsId) {
                            appItem.isSelect = true;
                        }
                    });
                }
            });
            this.$message.success(`已成功添加至购物车`);
        });
    }
    // 取消选择产品
    private propCancelApplication(value: any) {
        const isMoreDev = value.id === RESOURCE_CONFIG.MOREDEV_GOODSID;
        const selectMcFourArr = this.selectProductList.filter((el: any) => el.mcVersion === '4.0');
        if (isMoreDev && selectMcFourArr.length > 1) {
            this.$alert(`当WisIDE应用构建器平台（4.0）和应用同时购买时，无法单独移除，可以将已选的应用移除后再来操作`, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: (action) => {
                    // ...
                },
            });
            return;
        }
        const valueArr = [];
        const itemData = {
            appName: value.name,
            goodsId: value.id, // 选中商品传给后端的值
            goodsPriceType: value.goodsPriceType,
        };
        valueArr.push(itemData);
        this.getChoosePrice(valueArr, false).then(() => {
            if (value.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                this.productAppList.forEach((appItem: any) => {
                    if (appItem.id === value.id) {
                        appItem.isSelect = false;
                    }
                    // 20230721
                    // 已选中的产品中 4.0的产品除了开发平台，其它的应用数量
                    const selectListFourApplicationLen = this.selectProductList
                        .filter((el: any) =>
                        (
                            el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                        &&
                            el.mcVersion === '4.0'
                        &&
                            el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                        )).length;
                    // 当已选中的产品没有4.0应用，开发平台也要一起 设置成未选中状态
                    if (selectListFourApplicationLen === 0 && appItem.id === RESOURCE_CONFIG.MOREDEV_GOODSID) {
                        appItem.isSelect = false;
                    }
                });
            } else if (value.goodsPriceType === RESOURCE_CONFIG.SAAS_GOODSPRICETYPE) {
                this.productSaaSList.forEach((appItem: any) => {
                    if (appItem.id === value.id) {
                        appItem.isSelect = false;
                    }
                });
            } else if (value.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
                this.serviceList.forEach((appItem: any) => {
                    if (appItem.id === value.id) {
                        appItem.isSelect = false;
                    }
                });
            }
            this.$message.success(`您已移除该产品`);
        });
    }
    // 去移除
    private propHandleGotoRemove() {
        this.chooseFlag = false;
        this.handleSelectProductList(1);
    }
    // 计算价格添加，没有问题的情况下加入到已选列表中
    // 计算价格的时候，有一部分需要判断是否有服务器的需求或者是有试用服务器没有正式服务器，需要前端添加服务器资源
    private getChoosePrice(itemDataList: any, isAdd: boolean, isHandleCommand = false) {
        const paramData: any = {};
        paramData.goodsList	= [];
        // 已选购产品中 是否有 服务器
        const selectedServerLen = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID).length;
        // 已选购产品中 服务器
        const selectedServer = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID);
        // 已选购产品中 是否有 oracle
        const selectedOracle = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
        // 当前选购产品中 是否有 oracle
        const chosenOracle = itemDataList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
        // 当前选购产品中 是否有 应用
        const chosenApplicationLen = itemDataList
                .filter((el: any) => el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE).length;
        // 当前选购产品中 除了oracle数据库 理论上剩下来的都是应用，如果数量大于1，说明数据有问题
        const chosenProductList = itemDataList
            .filter((el: any) => {
                return el.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID && el.goodsId !== RESOURCE_CONFIG.GRANT_GOODSID;
            });
        // 当前选购产品中 是否 包含服务器，如果有 说明数据有误
        const chosenServerList = itemDataList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID);
        // 当前选购产品中 是否 包含授权用户数
        const chosenGrantList = itemDataList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        // 当前选购产品中 是否 包含服务，goodsPriceType是特意返回过来的 其它类型的商品没有
        const chosenServiceList = itemDataList
                .filter((el: any) => el.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE);
         // 已选购产品中 是否有 包含授权用户数
        const selectedGrant = this.selectProductList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
        if (chosenProductList.length !== 1) {
            this.$message.warning('选购的产品有误');
        }
        const itemData = chosenProductList[0];
        // 把已选的产品，除了服务器、用户数、oracle数据库先全部筛选出来，因为这三个比较特殊需要特殊处理一遍
        // 20221219 添加授权用户数筛选
        // 20230506 添加mc4.0平台筛选
        this.selectProductList.forEach((item: any, index: number) => {
            if (item.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.ORACLE_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.GRANT_GOODSID
                && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                if (!isAdd && item.goodsId === itemData.goodsId) {
                    // ...
                } else {
                    paramData.goodsList.push(item);
                }
            }
        });
        // 20230721
        // 如果中的商品不是应用，开发平台 不能移除
        // 添加的时候有 orderGoodsSceneType，移除的时候 只有 goodsPriceType
        this.selectProductList.forEach((item: any) => {
            if (isAdd && itemData.orderGoodsSceneType !== RESOURCE_CONFIG.APP_GOODSSCENETYPE
                && item.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID) {
                paramData.goodsList.push(item);
            } else if (!isAdd && itemData.goodsPriceType !== RESOURCE_CONFIG.APP_GOODSPRICETYPE
                && item.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID) {
                paramData.goodsList.push(item);
            }
        });

        if (itemData.mcVersion === '4.0' && this.serverIdMoreDev && this.serverIdMoreDev.toString() !== '-1') {
            itemData.serverId = this.serverIdMoreDev;
        }
        if (isAdd) {
            paramData.goodsList.push(itemData);
        }
        // 当添加的时候 如果选购的产品包含 授权用户数，或者已选购的产品包含 授权用户数 的情况下需要 添加 授权用户数item
        // 如果是取消的时候 如果不是取消 授权用户数产品，并且已选购的产品包含 授权用户数，理论上这种情况下 是取消服务
        let isAddGrant = false;
        if (isAdd && chosenGrantList.length > 0) {
            isAddGrant = true;
        } else if (chosenGrantList.length === 0 && selectedGrant.length > 0 && chosenServiceList.length > 0) {
            isAddGrant = true;
        }
        if (isAddGrant) {
            let grantData: any = {};
            if (chosenGrantList.length > 0) {
                grantData = Object.assign({}, chosenGrantList[0]);
            } else if (selectedGrant.length > 0) {
                const selectedGrantTab = this.selectProductList
                    .find((el: any) => el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID);
                grantData = Object.assign({}, selectedGrantTab);
            }
            const grantNumberData: any = {
                goodsId: RESOURCE_CONFIG.GRANT_GOODSID, // 选中商品传给后端的值
                appName: '授权用户数',
                orderDetailNumber: grantData.orderDetailNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: 999, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.GRANT_GOODSPRICETYPE,
                isBuyout: true,
            };
            if (grantData.skuId && grantData.skuId !== -1) {
                grantNumberData.skuId = grantData.skuId;
            }
            paramData.goodsList.push(grantNumberData);
        }
        // 添加数据库
        let isOracle = false;
        let oracleData: any = {};
        // 查看选择的产品是是否有oracle数据库，如果有的，做数量的修改
        if (selectedOracle.length > 0) {
            oracleData = Object.assign({}, selectedOracle[0]);
            // 判断选购列表中是否有oracle数据库，有并且下一个添加的是应用这需要对oracle数据进行操作
            if (isAdd && itemData.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                oracleData.orderDetailNumber += 1;
            // 替换的时候只有goodsPriceType，添加的时候只有orderGoodsSceneType
            } else if (!isAdd && itemData.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                oracleData.orderDetailNumber -= 1;
            }
        } else if (selectedOracle.length === 0 && chosenOracle.length > 0) {
            oracleData = Object.assign({}, chosenOracle[0]);
            if (isAdd) {
                oracleData.orderDetailNumber = 1;
            }
        }
        if (oracleData && oracleData.name !== '' && oracleData.orderDetailNumber > 0) {
            isOracle = true;
            const oracleObj = {
                goodsId: RESOURCE_CONFIG.ORACLE_GOODSID, // 选中商品传给后端的值
                appName: 'Oracle',
                orderDetailNumber: oracleData.orderDetailNumber, // 选中商品数量(累加)
                orderDetailTimeNumber: oracleData.orderDetailTimeNumber, // 时长
                cpu: oracleData.cpu,
                memory: oracleData.memory,
                harddisk: oracleData.harddisk,
                orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE,
            };
            paramData.goodsList.push(oracleObj);
            paramData.goodsList.forEach((item: any) => {
                // 添加到请求参数的应用，除了MoreDev 平台，其它的多添加上oracle配置
                if (item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                    && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                    item.cpu = oracleData.cpu;
                    item.memory = oracleData.memory;
                    item.harddisk = oracleData.harddisk;
                }
            });
        }
        // 添加服务器 3.0服务器
        const goodsListApplicationLen = paramData.goodsList
            .filter((el: any) =>
            (
                el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            &&
                el.mcVersion === '3.0'
            )).length;
        // 如果选购的产品或者已选购 3.0应用，并且已拥有服务器列表里 没有3.0服务器，需要添加一个3.0服务器
        if (goodsListApplicationLen > 0
            && this.companyServerObj
            && this.companyServerObj.serverType !== SERVER_CONFIG.SERVER_TYPE) {
            if (chosenServerList.length > 1) {
                this.$message.warning('服务器配置有误');
            }
            // 第一次选择应用接收一下服务器时长
            if (selectedServerLen === 0 && chosenApplicationLen > 0) {
                this.serverTimeNumber = itemData.serverTimeNumber;
                this.serverDBType = itemData.dbType;
            }
            let userSysTypeLen = this.companyServerObj.userSysTypeInfoList
                ? this.companyServerObj.userSysTypeInfoList.length : 0;
            if (this.companyServerObj.userSysTypeInfoList) {
                paramData.goodsList.forEach((el: any) => {
                    if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                        // 查看已拥有的应用，和当前购买的应用相同的有几个，这里当成是试用数量
                        // 如果goodsId一样 pg就转正算一个 oracle需要加一个数量
                        const userSysTypeInfoListLen = this.companyServerObj.userSysTypeInfoList
                            .filter((elItem: any) => elItem.goodsId === el.goodsId).length;
                        userSysTypeLen -= userSysTypeInfoListLen;
                        // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                        if (isOracle && userSysTypeInfoListLen > 0) {
                            userSysTypeLen += 1;
                        }
                    }
                });
            }
            const hasAppLen = goodsListApplicationLen + userSysTypeLen;
            console.log('hasAppLen', hasAppLen);
            // 当转正的时候，有部分试用服务器中的应用要一起算到正式里面，占一个数量
            // 通过数量来计算给多少服务器配置
            if (hasAppLen <= 2) {
                this.serverCpu = SERVER_CONFIG.TWO_VCUP;
                this.serverMemory = SERVER_CONFIG.TWO_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG.TWO_VCUP_HARDDISK;
            } else if (hasAppLen > 2 && hasAppLen <= 4) {
                this.serverCpu = SERVER_CONFIG.FOUR_VCUP;
                this.serverMemory = SERVER_CONFIG.FOUR_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG.FOUR_VCUP_HARDDISK;
            } else if (hasAppLen > 4 && hasAppLen <= 6) {
                this.serverCpu = SERVER_CONFIG.SIX_VCUP;
                this.serverMemory = SERVER_CONFIG.SIX_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG.SIX_VCUP_HARDDISK;
            } else if (hasAppLen > 6 && hasAppLen <= 8) {
                this.serverCpu = SERVER_CONFIG.EIGHT_VCUP;
                this.serverMemory = SERVER_CONFIG.EIGHT_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG.EIGHT_VCUP_HARDDISK;
            } else if (hasAppLen > 8 && hasAppLen <= 12) {
                this.serverCpu = SERVER_CONFIG.TWELVE_VCUP;
                this.serverMemory = SERVER_CONFIG.TWELVE_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG.TWELVE_VCUP_HARDDISK;
            }
            // 如果已选的配置大于默认配置，则使用已选配置
            if (selectedServer.length === 1) {
                this.serverCpu =
                    selectedServer[0].cpu > this.serverCpu ? selectedServer[0].cpu : this.serverCpu;
                this.serverMemory =
                    selectedServer[0].memory > this.serverMemory ? selectedServer[0].memory : this.serverMemory;
                this.serverHardDisk =
                    selectedServer[0].harddisk > this.serverHardDisk ? selectedServer[0].harddisk : this.serverHardDisk;
            }
            const serverObj = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                appName: '云服务器',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                cpu: this.serverCpu,
                memory: this.serverMemory,
                harddisk: this.serverHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                dbType: this.serverDBType,
            };
            if (isAddGrant) {
                // 买断的时候 给一个服务器的时长 给免费配置
                serverObj.orderDetailTimeNumber = this.serverFreeTab.freeTimeNumber;
            }
            paramData.goodsList.push(serverObj);
            const userNumberObj = {
                goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                appName: '云用户数',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                userNumber: this.userNumber,
            };
            if (isAddGrant) {
                // 买断的时候 给一个云用户数的时长 给免费配置
                userNumberObj.orderDetailTimeNumber = this.serverFreeTab.freeTimeNumber;
            }
            paramData.goodsList.push(userNumberObj);
        }
        // 添加服务器 4.0服务器
        const goodsListFourApplicationLen = paramData.goodsList
            .filter((el: any) =>
            (
                el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            &&
                el.mcVersion === '4.0'
            )).length;
        // 如果选购的产品或者已选购 4.0应用，并且已拥有服务器列表里 没有4.0服务器，需要添加一个4.0服务器
        if (goodsListFourApplicationLen > 0
            && this.companyServerFourObj
            && this.companyServerFourObj.serverType !== SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
            if (chosenServerList.length > 1) {
                this.$message.warning('服务器配置有误');
            }
            // 第一次选择应用接收一下服务器时长
            if (selectedServerLen === 0 && chosenApplicationLen > 0) {
                this.serverTimeNumber = itemData.serverTimeNumber;
                this.serverDBType = itemData.dbType;
            }
            // 当正式4.0服务器列表为空的时候，先得到4.0试用服务器中已试用的应用列表
            let userSysTypeLen = 0;
            if (this.companyServerFourList.length === 0 && this.companyServerFourTrialObj.userSysTypeInfoList) {
                const userSysTypeInfoList = this.companyServerFourTrialObj.userSysTypeInfoList;
                // 得到试用服务器应用列表中，不是关联安装商品的数量，这样计算会遗漏掉关联安装商品的数量
                // const isNotRelatedList =
                //     userSysTypeInfoList.filter((elItem: any) => elItem.isRelatedInstall === false);
                // 这里直接使用，试用服务器里面应用列表的数量，加上后面已关联安装商品的数量，可能会造成数据过大的情况
                // 注：没有做到已选的每个商品从ChooseDialog组件中传关联安装列表到这里，去跟试用服务器应用列表对比。
                // 商品详情GoodsMain中是只有一个商品有对比，这里没有对比，因为这里会选多个商品，对比的有点多
                userSysTypeLen = userSysTypeInfoList.length;
            }
            console.log('isNotRelatedListLen', userSysTypeLen);
            if (this.companyServerFourList.length === 0 && this.companyServerFourTrialObj.userSysTypeInfoList) {
                paramData.goodsList.forEach((el: any) => {
                    if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                        // 查看已拥有的应用，和当前购买的应用相同的有几个，这里当成是试用数量
                        // 如果goodsId一样 pg就转正算一个 oracle需要加一个数量
                        const userSysTypeInfoListLen = this.companyServerFourTrialObj.userSysTypeInfoList
                            .filter((elItem: any) => elItem.goodsId === el.goodsId).length;
                        userSysTypeLen -= userSysTypeInfoListLen;
                        // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                        if (isOracle && userSysTypeInfoListLen > 0) {
                            userSysTypeLen += 1;
                        }
                    }
                });
                // 查看mc4.0使用服务器里 是否有平台，平台不算应用，需要扣除相关数量
                const moreDevLen = this.companyServerFourTrialObj.userSysTypeInfoList
                    .filter((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID).length;
                if (moreDevLen > 0) {
                    userSysTypeLen -= moreDevLen;
                }
                // 如果选择购买时是oracle数据库，因为试用服务器是pg数据库，这样转正就需要pg版和oracle版共存的
                if (isOracle && moreDevLen > 0) {
                    userSysTypeLen += moreDevLen;
                }
            }
            // 查看已选中的产品 是否有 开发平台呢
            const hasMoreDev = paramData.goodsList.some((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID);
            // 20230509还有一个平台的数量，这里是1是开发平台的数量
            let hasAppFOurLen = goodsListFourApplicationLen + userSysTypeLen;
            // 这里1，如果第一次添加需要添加1，如果不是第一次就已经在goodsListFourApplicationLen里面了
            if (!hasMoreDev) {
                hasAppFOurLen += 1;
            }
            // 计算所有已选产品，包含了多少个关联安装商品
            let installRelatedLenTotal = 0;
            paramData.goodsList.forEach((el: any) => {
                if (el.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE) {
                    installRelatedLenTotal += el.installRelatedLen || 0;
                }
            });
            console.log("paramData.goodsList", paramData.goodsList);
            console.log('installRelatedLenTotal', installRelatedLenTotal);
            hasAppFOurLen += installRelatedLenTotal;
            console.log('hasAppFOurLen', hasAppFOurLen);
            // 当转正的时候，有部分试用服务器中的应用要一起算到正式里面，占一个数量
            // 通过数量来计算给多少服务器配置
            // 理论上4.0，没有产品，这里先做一次适配
            if (hasAppFOurLen <= 2) {
                this.serverCpu = SERVER_CONFIG_MC_FOUR.FOUR_VCUP;
                this.serverMemory = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG_MC_FOUR.FOUR_VCUP_HARDDISK;
            } else if (hasAppFOurLen > 2 && hasAppFOurLen <= 4) {
                this.serverCpu = SERVER_CONFIG_MC_FOUR.SIX_VCUP;
                this.serverMemory = SERVER_CONFIG_MC_FOUR.SIX_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG_MC_FOUR.SIX_VCUP_HARDDISK;
            } else if (hasAppFOurLen > 4 && hasAppFOurLen <= 6) {
                this.serverCpu = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP;
                this.serverMemory = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG_MC_FOUR.EIGHT_VCUP_HARDDISK;
            } else {
                this.serverCpu = SERVER_CONFIG_MC_FOUR.OTHER_VCUP;
                this.serverMemory = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_MEMORY;
                this.serverHardDisk = SERVER_CONFIG_MC_FOUR.OTHER_VCUP_HARDDISK;
            }
            // 如果已选的配置大于默认配置，则使用已选配置
            if (selectedServer.length === 1) {
                this.serverCpu =
                    selectedServer[0].cpu > this.serverCpu ? selectedServer[0].cpu : this.serverCpu;
                this.serverMemory =
                    selectedServer[0].memory > this.serverMemory ? selectedServer[0].memory : this.serverMemory;
                this.serverHardDisk =
                    selectedServer[0].harddisk > this.serverHardDisk ? selectedServer[0].harddisk : this.serverHardDisk;
            }
            const serverObj = {
                goodsId: RESOURCE_CONFIG.SERVER_GOODSID, // 选中商品传给后端的值
                appName: '云服务器',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                cpu: this.serverCpu,
                memory: this.serverMemory,
                harddisk: this.serverHardDisk,
                orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE,
                dbType: this.serverDBType,
            };
            if (isAddGrant) {
                // 买断的时候 给一个服务器的时长 给免费配置
                serverObj.orderDetailTimeNumber = this.serverFreeFourTab.freeTimeNumber;
            }
            paramData.goodsList.push(serverObj);
            const userNumberObj = {
                goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                appName: '云用户数',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.serverTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                userNumber: this.userNumber,
            };
            if (isAddGrant) {
                // 买断的时候 给一个云用户数的时长 给免费配置
                userNumberObj.orderDetailTimeNumber = this.serverFreeFourTab.freeTimeNumber;
            }
            paramData.goodsList.push(userNumberObj);
            // 新开4.0产品需要添加一个 开发平台
            const moreDevArr = paramData.goodsList
                .filter((el: any) => el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID);
            if (moreDevArr.length <= 0) {
                const moreDevGoods: any = {
                    appName: 'MoreDev 低代码开发平台',
                    goodsId: RESOURCE_CONFIG.MOREDEV_GOODSID, // 选中商品传给后端的值
                    skuId: process.env.VUE_APP_URL === 'https://cloud.morewis.com'
                        ? RESOURCE_CONFIG.MOREDEV_SKUID
                        : undefined, // 开发平台 生产环境中有规格
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverObj.orderDetailTimeNumber, // 时长 跟云服务器时长保持一致
                    orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE,
                    dbType: this.serverDBType, // itemData.dbType
                    installRelatedLen: 0,
                    mcVersion: '4.0',
                };
                paramData.goodsList.push(moreDevGoods);
                const oracleTab = paramData.goodsList
                    .find((el: any) => el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID);
                if (oracleTab && oracleTab.cpu) {
                    paramData.goodsList.forEach((el: any) => {
                        if (el.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                            // 如果有oracle，数量添加1
                        } else if (el.goodsId === RESOURCE_CONFIG.MOREDEV_GOODSID) {
                            // 开发平台的话，添加cpu，内存，硬盘参数
                        }
                    });
                }
            }
        }
        if (goodsListApplicationLen > 0) {
            this.mcVersion = 3;
        } else if (goodsListFourApplicationLen > 0) {
            this.mcVersion = 4;
        }
        if (paramData.goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                this.allProductTab = {
                    serverMoney: 0,
                    dbMoney: 0,
                    discountMoney: 0,
                    totalMoney: 0,
                };
                this.selectProductList = [].concat(paramData.goodsList);
                resolve({success: true});
            });
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck = true;
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price', {}, 1)
            .then((res: any) => {
                if (res.success) {
                    this.allProductTab = {
                        serverMoney: res.serverMoney,
                        dbMoney: res.dbMoney,
                        discountMoney: res.discountMoney,
                        totalMoney: res.totalMoney,
                    };
                    this.selectProductList = [].concat(paramData.goodsList);
                    console.log('this.selectProductList', this.selectProductList);
                    resolve(res);
                } else {
                    reject();
                }
            }).catch((err: any) => {
                reject();
                this.alertMsg(err, paramData, isHandleCommand);
            });
        });
    }
    private alertMsg(err: any, paramData: any, isHandleCommand = false) {
        // 正常（续费升级特殊的时候不计算正常范围）购买4.0应用时，才能切换服务器，过滤单独mc4.0平台的情况
        const isFourMCVersion = paramData.goodsList.some((item: any) =>
        (
            item.mcVersion === '4.0'
            && item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
            && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
        ));
        const isMoreDevHandle = isFourMCVersion || isHandleCommand;
        if (!isMoreDevHandle && (err.code === 3004 || err.code === 3005 || err.code === 3006)) {
            this.errorFlag = true;
            this.errorMessage = err.message;
        } else if (isMoreDevHandle
            && (err.code === 3004 || err.code === 3005 || err.code === 3006 || err.code === 400013)) {
            // mc4.0产品，资源不足或者服务器过期另外提示
            let msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"的配置过低，
                如需部署新的应用，请先升级云服务器配置，或切换其他云服务器。`;
            if (err.code === 400013) {
                msg = `云服务器"<span style="color:#409eff">${this.computedServerName}</span>"已过期，
                    如需部署新的应用，请先续费云服务器。`;
            }
            this.$alert(msg, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                    // ...
                },
            });
        } else if (err.code === 400001 || err.code === 400002 || err.code === 400003
            || err.code === 400010 || err.code === 400011 || err.code === 400013) {
            // buyFlag=1 没有提示的errCode提示
            this.$alert(err.message, '提示', {
                showClose: false,
                confirmButtonText: '我知道了',
                callback: (action) => {
                    // ...
                },
            });
        }
    }
    // 计算价格添加，调整服务器和oracle时使用
    private getResetProductPrice(itemData: any) {
        const paramData: any = {};
        paramData.goodsList	= [];
        this.selectProductList.forEach((item: any, index: number) => {
            if (item.goodsId === itemData.goodsId) {
                paramData.goodsList.push(itemData);
            } else {
                paramData.goodsList.push(item);
            }
            // 如果是更新oracle，把选中的应用也更新一下配置
            if (itemData.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID
                && item.orderGoodsSceneType === RESOURCE_CONFIG.APP_GOODSSCENETYPE
                && item.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID) {
                item.cpu = itemData.cpu;
                item.memory = itemData.memory;
                item.harddisk = itemData.harddisk;
            }
        });
        if (paramData.goodsList.length === 0) {
            return new Promise((resolve, reject) => {
                this.allProductTab = {
                    serverMoney: 0,
                    dbMoney: 0,
                    discountMoney: 0,
                    totalMoney: 0,
                };
                this.selectProductList = [].concat(paramData.goodsList);
                resolve({success: true});
            });
        }
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        return new Promise((resolve, reject) => {
            this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price', {}, 1)
            .then((res: any) => {
                if (res.success) {
                    this.allProductTab = {
                        serverMoney: res.serverMoney,
                        dbMoney: res.dbMoney,
                        discountMoney: res.discountMoney,
                        totalMoney: res.totalMoney,
                    };
                    this.selectProductList = [].concat(paramData.goodsList);
                    resolve(res);
                } else {
                    // ...
                }
            }).catch((err: any) => {
                this.alertMsg(err, paramData);
            });
        });
    }
    // 隐藏服务器和数据库配置悬浮框的回调
    private propCloseServerOracle() {
        this.isInitServerOracle = false;
    }
    private propAdjust(value: number) {
        if (value === 1) {
            this.resetServerFlag = true;
        } else if (value === 3) {
            this.resetOracleFlag = true;
        }
    }
    // 调整服务器配置的回调
    private propResetServerCloseMsg(value: any) {
        if (value && !value.goodsId) {
            this.resetServerFlag = false;
            return;
        }
        this.getResetProductPrice(value);
        this.resetServerFlag = false;
    }
    // 调整Oracle配置的回调
    private propResetOracleCloseMsg(value: any) {
        if (value && !value.goodsId) {
            this.resetOracleFlag = false;
            return;
        }
        this.getResetProductPrice(value);
        this.resetOracleFlag = false;
    }
    // 调整云用户数配置的回调
    private propResetUserNumberCloseMsg(value: any) {
        if (value && !value.goodsId) {
            this.resetUserNumberFlag = false;
            return;
        }
        this.getResetProductPrice(value);
        this.resetUserNumberFlag = false;
    }
    // 选购明细操作
    private handleSelectProductList(handleFlag: number) {
        if (handleFlag === 1) {
            this.isSelectProductList = !this.isSelectProductList;
            this.isInitServerOracle = false;
        } else if (handleFlag === 0) {
            this.isSelectProductList = false;
        } else {
            return;
        }
    }
    // 明细中调整和移除回调
    private propHandleProduct(productItem: any) {
        if (productItem && productItem.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
            this.resetServerFlag = true;
            return;
        } else if (productItem && productItem.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID) {
            this.resetUserNumberFlag = true;
            return;
        } else if (productItem && productItem.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
            this.resetOracleFlag = true;
            return;
        }
        this.$confirm(`请确定是否要移除"${productItem.name}"产品?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.propCancelApplication(productItem);
        }).catch(() => {
            // 取消
        });
    }
    // 明细中清空所有产品回调
    private propHandleClearAll() {
        this.productAppList.forEach((appItem: any) => {
            appItem.isSelect = false;
        });
        this.productSaaSList.forEach((appItem: any) => {
            appItem.isSelect = false;
        });
        this.serviceList.forEach((appItem: any) => {
            appItem.isSelect = false;
        });
        this.allProductTab = {
            serverMoney: 0,
            dbMoney: 0,
            discountMoney: 0,
            totalMoney: 0,
        };
        this.selectProductList = [].concat([]);
        this.isSelectProductList = false;
    }
    private goAnchor(anchorNum: number) {
        if (document.getElementById(`anchor${anchorNum}`)) {
            document.getElementById(`anchor${anchorNum}`)!.scrollIntoView({
                behavior: "smooth",
            });
            this.anchorIndex = anchorNum;
        }
    }
    private setRenewalUpgrad(serverId: string, sceneType: number) {
        this.serverId = serverId + '';
        this.orderGoodsSceneType = sceneType;
        this.renewalUpgradFlag = true;
    }
    private renewalUpgradCloseMsg() {
        this.renewalUpgradFlag = false;
    }
    private gotoServer(typeIndex: number, mcVersion: number) {
        // 升级 /user/order/buyServer.htm?serverId=2729&payType=2&serverType=1&userId=53415
        // 续费 /user/order/buyServer.htm?serverId=2729&payType=1&serverType=1&userId=53415
        const serverId = mcVersion === 4 ? this.companyServerFourObj.serverId : this.companyServerObj.serverId;
        const sceneType = typeIndex === 1 ? RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW
                                : RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE;
        this.setRenewalUpgrad(serverId, sceneType);
    }
    private gotoServerMoreDev(typeIndex: number, serverId: any) {
        // 升级 /user/order/buyServer.htm?serverId=2729&payType=2&serverType=1&userId=53415
        // 续费 /user/order/buyServer.htm?serverId=2729&payType=1&serverType=1&userId=53415;
        const sceneType = typeIndex === 1 ? RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW
                                : RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE;
        this.setRenewalUpgrad(serverId, sceneType);
    }
    private propErrorCloseMsg(num: any) {
        if (num === 1) {
            this.propHandleGotoRemove();
        } else if (num === 2) {
            this.gotoServer(2, this.mcVersion);
        }
        this.errorFlag = false;
    }
    get computedServerName() {
        const tab = this.serverFourList
            .find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        if (this.serverIdMoreDev.toString() === '-1' ) {
            return '新开云服务器';
        }
        return tab && tab.serverName ? tab.serverName : '请选择服务器';
    }
    get companyServerFourObj() {
        const tab = this.companyServerFourList
            .find((el: any) => el.serverId.toString() === this.serverIdMoreDev.toString());
        return tab || {};
    }
    private handleCommand(com: string) {
        if (com === this.serverIdMoreDev) {
            return;
        }
        const goodsListFourApplicationLen = this.selectProductList
                .filter((el: any) => (el.mcVersion === '4.0' )).length;
        if (goodsListFourApplicationLen === 0) {
            this.serverIdMoreDev = com;
            return;
        }
        // 先设置serverIdMoreDev，如果报错再还原
        const serverIdMoreDevPrev = this.serverIdMoreDev;
        this.serverIdMoreDev = com;
        // 模拟一个添加动作,把一个应用和数据扣出来，模拟成添加产品的操作
        const selectProductListCopy = JSON.parse(JSON.stringify(this.selectProductList));
        const selectProductList: any = [];
        const itemDataList: any = [];
        const moreDevObj = this.selectProductList.find((el: any) =>
            (
                el.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
            &&
                el.mcVersion === '4.0'
            ));
        this.selectProductList.forEach((item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                const oracleObj = JSON.parse(JSON.stringify(item));
                oracleObj.orderDetailNumber = 1;
                itemDataList.push(oracleObj);
                if (item.orderDetailNumber > 1) {
                    const selectOracleObj = JSON.parse(JSON.stringify(item));
                    selectOracleObj.orderDetailNumber = item.orderDetailNumber - 1;
                    selectProductList.push(oracleObj);
                }
            }
            if (moreDevObj && moreDevObj.goodsId === item.goodsId) {
                itemDataList.push(item);
            } else {
                selectProductList.push(item);
            }
        });
        this.selectProductList = [];
        this.selectProductList = JSON.parse(JSON.stringify(selectProductList));
        this.getChoosePrice(itemDataList, true, true).then(() => {
            this.serverIdMoreDev = com;
        }).catch(() => {
            this.selectProductList = JSON.parse(JSON.stringify(selectProductListCopy));
            this.serverIdMoreDev = serverIdMoreDevPrev;
        });
    }
}
