
import { AsyncComponent } from 'vue';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'AppDetail',
})
export default class AppDetail extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private dialogFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值

    private showDialog: boolean = false; // 弹窗显示与关闭
    private showPassword: boolean = false;

    @Watch('dialogFlag') // 监听父组件传过来的值
    private dialogFlagFun(value: boolean) {
        this.showDialog = value;
    }

    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    //  确定按钮
    private closeDialog(num: number) {
        this.closeTodo(num); // 给父组件传值
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        this.closeTodo(0); // 给父组件传值
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(0); // 给父组件传值
    }
    get textPassword() {
        let str = this.itemData.dbAdmin || '';
        if (this.itemData.dbAdminPassword) {
            str += "/" + this.itemData.dbAdminPassword || '';
        }
        let newStr = "";
        // replace(/./g, "*")
        for (const value of str) {
            newStr += "*";
        }
        if (this.showPassword) {
            return str;
        } else {
            return newStr;
        }
    }
    // 数据库 登录
    private openDBSsh(runStatus: number, dbId: number) {
        // -3，已拒绝；-2，已卸载；-1，待部署；0，已停止；1，启动中；2，运行中；3，已过期
        if (runStatus === -1 || runStatus === 1) {
            this.$message.warning('数据库未运行，无法访问，请联系管理员！');
        } else {
            window.open(`/user/server/databaselogin.htm?id=${dbId}`);
        }
    }
}
