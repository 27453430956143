
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
  name: 'ServiceItem',
})
export default class ServiceItem extends Vue {
    private priceNum: number = 0;
    private priceUnit: string = '/人天';
    private itemTab: any = {
        text: '',
    };
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private created() {
        this.itemTab = Object.assign({}, this.itemData);
        // 服务
        if (this.itemTab.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
            this.priceNum = this.itemTab.price ? this.itemTab.price.toFixed(2) : Number(0).toFixed(2);
            this.priceUnit = '/人天';
            if (this.itemTab.pricingScheme === 1) {
                this.priceUnit = '';
            }
        }
    }
    // 给父组件传值
    @Emit('clickBuyService')
    private clickBuyServiceTodo(): object {
        return this.itemTab;
    }
    private openService() {
        this.clickBuyServiceTodo();
    }
}
