
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: "PerformanceEcharts",
})
export default class PerformanceEcharts extends Vue {
    @Prop(Object) private chartData!: {
        serverHarddiskNum: 0,
        serverCpuNum: 0,
        serverMemoryNum: 0,
    };
    private blockBlackFlag = false;
    private contentTitle: string = "性能监控";
    private $echarts: any;
    private myChart: any = null;
    private option: any = {
        tooltip: {
            show: true,
            formatter: "{b}：{c}%",
        },
        toolbox: {
            show: false,
        },
        series : [
            {
                name: '内存使用率',
                type: 'gauge',
                z: 3,
                min: 0,
                max: 100,
                splitNumber: 5,
                radius: '50%',
                axisLine: {            // 坐标轴线
                    lineStyle: {       // 属性lineStyle控制线条样式
                        width: 10,
                        color: [[0.2, '#62b87b'], [0.8, '#1d9dc4'], [1, '#fc5831']],
                    },
                },
                axisTick: {            // 坐标轴小标记
                    length: 15,        // 属性length控制线长
                    lineStyle: {       // 属性lineStyle控制线条样式
                        color: 'auto',
                    },
                },
                splitLine: {           // 分隔线
                    length: 20,         // 属性length控制线长
                    lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                        color: 'auto',
                    },
                },
                axisLabel: {
                    show: false,
                    fontSize: 8,
                },
                pointer: {
                    width: 2,
                },
                title: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: '100',
                    fontSize: 12,
                    offsetCenter: [0, '80%'],
                },
                detail : {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    show: true,
                    fontSize: 10,
                    offsetCenter: [0, "50%"],
                    formatter: "{value}%",
                    color: '#333333',
                },
                itemStyle: {
                    color: '#1d9dc4',
                },
                data: [{value: 0, name: "内存"}],
            },
            {
                name: 'cpu使用率',
                type: 'gauge',
                center: ['20%', '55%'],    // 默认全局居中
                radius: '35%',
                min: 0,
                max: 100,
                endAngle: 45,
                splitNumber: 5,
                axisLine: {            // 坐标轴线
                    lineStyle: {       // 属性lineStyle控制线条样式
                        width: 8,
                        color: [[0.2, '#62b87b'], [0.8, '#1d9dc4'], [1, '#fc5831']],
                    },
                },
                axisLabel: {
                    show: false,
                    fontSize: 8,
                },
                axisTick: {            // 坐标轴小标记
                    length: 10,        // 属性length控制线长
                    lineStyle: {       // 属性lineStyle控制线条样式
                        color: 'auto',
                    },
                },
                splitLine: {           // 分隔线
                    length: 15,         // 属性length控制线长
                    lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                        color: 'auto',
                    },
                },
                pointer: {
                    width: 2,
                },
                itemStyle: {
                    color: '#1d9dc4',
                },
                title: {
                    fontWeight: '100',
                    fontSize: 12,
                    offsetCenter: [0, '90%'],       // x, y，单位px
                },
                detail: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    show: true,
                    fontSize: 10,
                    offsetCenter: [0, "60%"],
                    formatter: "{value}%",
                    color: '#333333',
                },
                data: [{value: 0, name: "cpu"}],
            },
            {
                name: '硬盘使用率',
                type: 'gauge',
                center: ['77%', '50%'],    // 默认全局居中
                radius: '35%',
                min: 0,
                max: 100,
                startAngle: 135,
                endAngle: -45,
                splitNumber: 5,
                axisLine: {            // 坐标轴线
                    lineStyle: {       // 属性lineStyle控制线条样式
                        width: 8,
                        color: [[0.2, '#62b87b'], [0.8, '#1d9dc4'], [1, '#fc5831']],
                    },
                },
                axisTick: {            // 坐标轴小标记
                    splitNumber: 5,
                    length: 10,        // 属性length控制线长
                    lineStyle: {        // 属性lineStyle控制线条样式
                        color: 'auto',
                    },
                },
                axisLabel: {
                    show: false,
                    fontSize: 8,
                },
                splitLine: {           // 分隔线
                    length: 15,         // 属性length控制线长
                    lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                        color: 'auto',
                    },
                },
                pointer: {
                    width: 2,
                },
                title: {
                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                    fontWeight: '100',
                    fontSize: 12,
                    offsetCenter: [0, '100%'],
                },
                itemStyle: {
                    color: '#1d9dc4',
                },
                detail : {
                    show: true,
                    fontSize: 10,
                    offsetCenter: [0, "60%"],
                    formatter: "{value}%",
                    color: '#333333',
                },
                data: [{value: 0, name: "硬盘"}],
            },
        ],
    };
    @Watch('chartData')
    private watchOption(val: object) {
        this.$nextTick(() => {
            this.drawCharts();
        });
    }
    private mounted() {
        this.$nextTick(() => {
            const echartDom: any = document.getElementById('myEcharts');
            this.myChart = this.$echarts.init(echartDom);
            this.drawCharts();
            window.onresize = () => {
                if (this.myChart) {
                    this.myChart.resize();
                }
            };
        });
    }
    private beforeDestroy() {
        if (!this.myChart) {
            return;
        }
        this.myChart.dispose();
        this.myChart = null;
    }
    private drawCharts() {
        this.option.series[0].data = [{value: this.chartData.serverMemoryNum || 0, name: "内存"}];
        this.option.series[1].data = [{value: this.chartData.serverCpuNum || 0, name: "cpu"}];
        this.option.series[2].data = [{value: this.chartData.serverHarddiskNum || 0, name: "硬盘"}];
        this.myChart.setOption(this.option);
    }
}
