
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';
import { formatTime, subDate, getDateDiffMonth } from '@/utils/utils';

@Component({
    name: 'RenewalDialog',
})
export default class RenewalDialog extends Vue {
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private itemTab: any = {
        isSelect: false,
    };
    private buyTypeArr: any = [];
    private buyType: number = 1; // 1: 月;2: 年; 3:永久;
    private shrinkStr: string = ''; // 缩略时的字符串
    // 服务器的timeNumber
    private serverTimeNumberSetList: any = [];
    private serverFreeTimeNumber: number = 0;
    // 产品默认时间列表
    private appTimeNumberSetList: any = [];
    private appTimeList: any = [];
    // 折扣列表
    private discountInfo: any = [];
    private discountList: any = '';
    // 所有商品价格
    private allProductTab: any = {
        appDiscountMoney: 0, // 产品优惠
        appMoney: 0, // 产品价格
        dbDiscountMoney: 0, // 数据库优惠
        dbMoney: 0, // 数据库价格
    };
    // dialog 相关数据
    private showDialog: boolean = false; // 弹窗显示与关闭
    private num: number = 1;
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        if (value) {
            this.$nextTick( () => {
                this.init();
            });
        } else {
            this.showDialog = value;
        }
    }
    @Watch('itemData') // 监听父组件传过来的值
    private itemDataFun(value: any) {
        // this.itemTab = Object.assign({}, value);
    }
    private init() {
        this.buyType = 1;
        this.itemTab.orderDetailTimeNumber = 1;
        this.getData();
    }

    // 获取应用续费数据
    private getData() {
        const paramData: any = {};
        paramData.mcId = this.itemData.mcId;
        if (this.userId !== '') {
            paramData.userId = this.userId;
        }
        this.$httpService
        .getData(paramData, `/apiProxy/api/frontend/company/systems/${this.itemData.appId}/renew-info`)
        .then((res: any) => {
            this.itemTab = res.goodsInfo;
            this.discountInfo = res.discountInfo;
            let diffMonth = 0;
            if (this.itemData.productCategory !== 1 && this.itemData.expirationTime) {
                diffMonth = getDateDiffMonth(this.itemData.expirationTime);
            }
            console.log('diffMonth', diffMonth);
            this.appTimeNumberSetList = res.buyTimeInfo.map((item: any) => ({
                name: item.serverSetName,
                number: item.serverSetNumber,
                disabled: diffMonth <= item.serverSetNumber ? false : true,
            }));
            this.$nextTick(() => {
                this.getBuyTab();
                this.propSelectApplication();
            });
        }).catch(() => {
            this.closeTodo(1);
        });
    }

    // 组装 购买方式 按月 按年 永久
    private getBuyTab() {
        this.buyTypeArr = [];
        if (this.itemTab.priceItems && this.itemTab.priceItems.length > 0) {
            // 时间选择中，有效的月份 有多少个
            const monthLen = this.appTimeNumberSetList.filter((el: any) => {
                return el.disabled === false && el.number < 12;
            }).length;
            // 时间选择中，有效的年份 有多少个
            const yearLen = this.appTimeNumberSetList.filter((el: any) => {
                return el.disabled === false && el.number >= 12;
            }).length;
            this.itemTab.priceItems.forEach((item: any) => {
                if (item.type === 2) {
                    this.buyTypeArr.push({
                        buyType: 1,
                        buyTypeName: '按月',
                        disabled: monthLen > 0 ? false : true,
                    });
                } else if (item.type === 3) {
                    this.buyTypeArr.push({
                        buyType: 2,
                        buyTypeName: '按年',
                        disabled: yearLen > 0 ? false : true,
                    });
                }
                // Saas 永久续费
                // if (this.itemData.productCategory === 1) {
                //     if (item.type === 1) {
                //         this.buyTypeArr.push({
                //             buyType: 3,
                //             buyTypeName: '买断',
                //         });
                //     } else if (item.type === 4) {
                //         this.buyTypeArr.push({
                //             buyType: 4,
                //             buyTypeName: '买断',
                //         });
                //     }
                // }
            });
        }
        if (this.buyTypeArr.length > 0) {
            this.buyTypeArr.sort((a: any, b: any) => a.buyType - b.buyType);
            const normalTypeLen = this.buyTypeArr.filter((el: any) => el.disabled === false).length;
            if (normalTypeLen === 0) {
                this.$message.warning('该产品过期时间太久，暂不支持线上续费，请联系您的客户经理处理');
                this.closeTodo(1);
                return;
            }
            this.buyType = this.buyTypeArr.find((el: any) => el.disabled === false).buyType;
            this.showDialog = true;
        } else {
            this.$message.warning('该产品不支持续费！');
            this.closeTodo(1);
            return;
        }
        this.getDiscountList(this.buyType);
        this.getAppTimeList();
    }

    // 组装时间列表 月 年
    private getAppTimeList() {
        this.appTimeList = [];
        if (this.appTimeNumberSetList.length === 0) {
            return;
        }
        this.appTimeNumberSetList.forEach((item: any) => {
            if (this.buyType === 1 && item.number > 0 && item.number < 12) {
                this.appTimeList.push(item);
            }
            if (this.buyType === 2 && item.number >= 12) {
                this.appTimeList.push(item);
            }
        });
        if (this.appTimeList.length > 0) {
            this.itemTab.orderDetailTimeNumber =
                this.appTimeList.find((el: any) => el.disabled === false).number;
        }
        if (this.buyType === 3 || this.buyType === 4) {
            this.itemTab.orderDetailTimeNumber = 999;
        }
    }

    // 给父组件传值
    @Emit('chooseClose')
    private closeTodo(num: number): any {
        if (num === 3) {
            const goodsListArr = this.assembleGoodsList();
            const goodsList = [].concat(goodsListArr);
            return goodsList;
        } else {
            return [];
        }
    }

    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            const goodsListArr = this.assembleGoodsList();
            if (this.itemData.productCategory === 0) {
                this.checkServerDbEndTime(goodsListArr, num);
            } else {
                const goodsList = [].concat(goodsListArr);
                this.$router
                .push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}&userData=${this.userId}`)
                .catch((err) => err);
                this.showDialog = false;
                this.closeTodo(num); // 给父组件传值
            }
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }

    // 查看服务器和数据库到期情况
    private checkServerDbEndTime(goodsListArr: any, num: number) {
        const paramData: any = {};
        if (this.userId !== '') {
            paramData.userId = this.userId;
        }
        paramData.mcId = this.itemData.mcId;
        this.$httpService.getData(paramData, `/apiProxy/api/frontend/goods/mc/goods/expire`)
        .then((res: any) => {
            const goodsList: any = [].concat(goodsListArr);
            const appEndTime = subDate(new Date(res.appEndTime), this.itemTab.orderDetailTimeNumber, 'M', 'yyyy-MM-dd hh:mm:ss');
            // 判断服务器到期时间
            if (res.serverId
                && res.serverEndTime
                && res.serverEndTime !== ''
                && new Date(appEndTime) > new Date(res.serverEndTime)) {
                const nowTimestamp = Date.parse(formatTime(new Date() + '', 'yyyy-MM-dd'));
                const serverEndTimeTimestamp = Date.parse(formatTime(res.serverEndTime, 'yyyy-MM-dd'));
                const serverEndTime = subDate(new Date(res.serverEndTime), this.itemTab.orderDetailTimeNumber, 'M', 'yyyy-MM-dd hh:mm:ss');
                let serverTimeNumber = this.itemTab.orderDetailTimeNumber;
                if (new Date() > new Date(serverEndTime)) {
                    const days = (nowTimestamp - serverEndTimeTimestamp) / (1 * 24 * 60 * 60 * 1000);
                    if (days > 365 * 5) {
                        this.$message.warning('您的服务器已经过期过久了，请联系管理员！');
                        return;
                    } else {
                        const divisionYearNum = Math.ceil(days / 365);
                        const divisionMonthNum = Math.ceil(days / 30);
                        if (divisionYearNum > 1) {
                            serverTimeNumber = divisionYearNum * 12;
                        } else if (divisionMonthNum > 12) {
                            serverTimeNumber = 24; // 2年
                        } else {
                            serverTimeNumber = divisionMonthNum;
                        }
                    }
                }
                // 服务器续费信息
                const serverItem: any = {
                    cpu: res.serverCpu,
                    goodsId: 1,
                    harddisk: res.serverHarddisk,
                    memory: res.serverMemory,
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: serverTimeNumber,
                    orderGoodsSceneType: RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW,
                    serverId: res.serverId,
                };
                goodsList.push(serverItem);
            }
            // 判断开发平台过期时间
            // 如果是4.0产品，当不是平台产品的话，应用续费后的时间大于平台过期时间需要同时续费平台
            if (res.platformServerAppId
                && this.itemTab.mcVersion === '4.0' && this.itemTab.goodsId !== RESOURCE_CONFIG.MOREDEV_GOODSID
                && res.platformEndTime
                && res.platformEndTime !== ''
                && new Date(appEndTime) > new Date(res.platformEndTime)) {
                const nowTimestamp = Date.parse(formatTime(new Date() + '', 'yyyy-MM-dd'));
                const platformEndTimeTimestamp = Date.parse(formatTime(res.platformEndTime, 'yyyy-MM-dd'));
                const platformEndTime = subDate(new Date(res.platformEndTime), this.itemTab.orderDetailTimeNumber, 'M', 'yyyy-MM-dd hh:mm:ss');
                let platformTimeNumber = this.itemTab.orderDetailTimeNumber;
                if (new Date() > new Date(platformEndTime)) {
                    const days = (nowTimestamp - platformEndTimeTimestamp) / (1 * 24 * 60 * 60 * 1000);
                    if (days > 365 * 5) {
                        this.$message.warning('您的服务器已经过期过久了，请联系管理员！');
                        return;
                    } else {
                        const divisionYearNum = Math.ceil(days / 365);
                        const divisionMonthNum = Math.ceil(days / 30);
                        if (divisionYearNum > 1) {
                            platformTimeNumber = divisionYearNum * 12;
                        } else if (divisionMonthNum > 12) {
                            platformTimeNumber = 24; // 2年
                        } else {
                            platformTimeNumber = divisionMonthNum;
                        }
                    }
                }
                // MoreDev平台模板
                const moreDevGoods: any = {
                    appName: 'MoreDev 低代码开发平台',
                    goodsId: RESOURCE_CONFIG.MOREDEV_GOODSID, // 选中商品传给后端的值
                    skuId: process.env.VUE_APP_URL === 'https://cloud.morewis.com'
                        ? RESOURCE_CONFIG.MOREDEV_SKUID
                        : undefined, // 开发平台 生产环境中有规格
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: platformTimeNumber, // 时长
                    orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE_RENEW,
                    serverId: this.itemTab.serverId,
                    serverAppId: res.platformServerAppId,
                    mcVersion: '4.0',
                };
                goodsList.push(moreDevGoods);
            }

            // 判断数据库到期时间
            if (res.dbId
                && res.dbType === 2
                && res.dbEndTime
                && res.dbEndTime !== ''
                && new Date(appEndTime) > new Date(res.dbEndTime)) {
                const nowTimestamp = Date.parse(formatTime(new Date() + '', 'yyyy-MM-dd'));
                const dbEndTimeTimestamp = Date.parse(formatTime(res.dbEndTime, 'yyyy-MM-dd'));
                const dbEndTime = subDate(new Date(res.dbEndTime), this.itemTab.orderDetailTimeNumber, 'M', 'yyyy-MM-dd hh:mm:ss');
                let dbTimeNumber = this.itemTab.orderDetailTimeNumber;
                if (new Date() > new Date(dbEndTime)) {
                    const days = (nowTimestamp - dbEndTimeTimestamp) / (1 * 24 * 60 * 60 * 1000);
                    if (days > 365 * 5) {
                        this.$message.warning('您的数据库已经过期过久了，请联系管理员！');
                        return;
                    } else {
                        const divisionYearNum = Math.ceil(days / 365);
                        const divisionMonthNum = Math.ceil(days / 30);
                        if (divisionYearNum > 1) {
                            dbTimeNumber = divisionYearNum * 12;
                        } else if (divisionMonthNum > 12) {
                            dbTimeNumber = 24; // 2年
                        } else {
                            dbTimeNumber = divisionMonthNum;
                        }
                    }
                }
                // 数据库续费信息
                const dbItem: any = {
                    cpu: res.dbCpu,
                    dbId: res.dbId,
                    goodsId: 3,
                    harddisk: res.dbHarddisk,
                    memory: res.dbMemory,
                    orderDetailNumber: 1, // 选中商品数量(固定不变的)
                    orderDetailTimeNumber: dbTimeNumber,
                    orderGoodsSceneType: RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW,
                    serverId: res.serverId,
                };
                goodsList.push(dbItem);
            }
            this.$router.push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}&userData=${this.userId}`)
            .catch((err) => err);
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        });
    }

    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private async buyClick(item: any) {
        if (item.disabled) {
            return;
        }
        const buyClickType = item.buyType;
        if (buyClickType !== this.buyType) {
            this.buyType = buyClickType;
            this.getAppTimeList();
            this.propSelectApplication();
            this.getDiscountList(buyClickType);
        }
    }

    // 获取折扣信息
    private getDiscountList(buyClickType: number) {
        this.discountList = '';
        const arr: any = [];
        if (buyClickType === 1) {
            this.itemTab.discountItems.forEach((item: any) => {
                if (item.number < 12) {
                    arr.push(`${item.number}个月${item.discount}折`);
                }
            });
        }
        if (buyClickType === 2) {
            this.itemTab.discountItems.forEach((item: any) => {
                if (item.number >= 12) {
                    arr.push(`${item.number / 12}年${item.discount}折`);
                }
            });
        }
        this.discountList = arr.join('，');
    }

    private selectTimeNumber() {
        this.propSelectApplication();
    }

    // 计算价格
    private propSelectApplication() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const goodsListArr = this.assembleGoodsList();
        paramData.goodsList = [].concat(goodsListArr);
        // if (this.userId !== '') {
        //     paramData.userList = unescape(this.userId).split(',');
        // }
        paramData.userCheck	= false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.allProductTab = {
                appDiscountMoney: res.appDiscountMoney, // 产品优惠
                appMoney: res.appMoney, // 产品价格
                dbDiscountMoney: res.dbDiscountMoney, // 数据库优惠
                dbMoney: res.dbMoney, // 数据库价格
            };
        });
    }
    private assembleGoodsList(): any {
        const goodsList = [];
        if (this.itemData.productCategory === 0) {  // MC===APP
            const timeNumListMax = Math.max.apply(Math, this.serverTimeNumberSetList.map((item: any) => item.number ));
            let serverTimeNum = 0;
            if (this.itemTab.orderDetailTimeNumber === 999) {
                serverTimeNum = timeNumListMax;
            } else if (this.itemTab.orderDetailTimeNumber > this.serverFreeTimeNumber) {
                serverTimeNum = this.itemTab.orderDetailTimeNumber;
            } else {
                serverTimeNum = this.serverFreeTimeNumber;
            }
            // 应用goods模板
            const itemData: any = {
                appName: this.itemTab.goodsTitle,
                goodsId: this.itemTab.goodsId, // 选中商品传给后端的值
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.itemTab.orderDetailTimeNumber || 1, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.APP_GOODSSCENETYPE_RENEW,
                suiteName: 'mc',
                serverTimeNumber: serverTimeNum,
                serverId: this.itemTab.serverId,
                serverAppId: this.itemTab.serverAppId,
            };
            if (this.itemTab.skuId) {
                itemData.skuId = this.itemTab.skuId;
            }
            goodsList.push(itemData);
        } else if (this.itemData.productCategory === 1) {   // SASS
            // SaaS goods模板
            const itemData: any = {
                appName: this.itemTab.goodsTitle,
                goodsId: this.itemTab.goodsId, // 选中商品传给后端的值
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.itemTab.orderDetailTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.SAAS_GOODSSCENETYPE_RENEW, // 20首次购买，21续费
                dbType: 0,
            };
            if (this.itemTab.skuId) {
                itemData.skuId = this.itemTab.skuId;
            }
            goodsList.push(itemData);
        }
        return goodsList;
    }
}
