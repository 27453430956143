
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';
import { assembleOrderConfirmBuyout } from '@/utils/assemble';
@Component({
    name: 'SelectProductList',
})
export default class SelectProductList extends Vue {
    private RESOURCE_CONFIG: any = RESOURCE_CONFIG;
    private itemTab: any = {
        text: '',
    };
    private itemArr: any = [];
    private productList: any = [];
    @Prop({
        type: String,
        default: false,
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: () => [],
    }) private selectProductList!: any; // 接收父组件传过来的值
    @Watch('selectProductList', {immediate: true, deep: true}) // 监听父组件传过来的值
    private selectProductListFun(value: any) {
        this.itemArr = [].concat(value);
        this.orderCreate();
    }
    private created() {
        // ...
    }
    private orderCreate() {
        if (this.itemArr.length === 0) {
            this.productList = [];
            return;
        }
        // 创建订单数据
        const paramData: any = {};
        paramData.goodsList = this.itemArr;
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/orders/create')
        .then((res: any) => {
            this.productList = [];
            res.orderList.forEach((itemRes: any) => {
                itemRes.detailList.forEach((item: any) => {
                    this.productList.push({
                        goodsId: item.goodsId,
                        id: item.goodsId,
                        name: item.goodsTitle,
                        cpu: item.cpu,
                        memory: item.memory,
                        harddisk: item.harddisk,
                        userNumber: item.userNumber,
                        orderDetailTimeNumber: item.payTimeName,
                        orderDetailNumber: item.orderDetailNumber,
                        price: '￥ ' + item.orderDetailMoney && '￥ ' + item.orderDetailMoney.toFixed(2),
                        goodsPriceType: item.goodsPriceType,
                        skuTitle: item.skuTitle,
                    });
                });
            });
            // 买断的时候，不显示 服务器 和 云用户数
            if (this.isBuyCompletely()) {
                // 买断产品和授权用户数 上下显示
                // false 是资源购买页也显示云服务器和用户数，为了用户可以主动调整相关配置
                const detailListCopy: any = assembleOrderConfirmBuyout(this.productList, false);
                this.productList = [].concat(detailListCopy);
            }
        });
    }
    private isBuyCompletely() {
        const grantLen = this.productList
            .filter((item: any) => item.goodsId === RESOURCE_CONFIG.GRANT_GOODSID)
            .length;
        return grantLen > 0 ? true : false;
    }
    // 给父组件传值
    @Emit('clickCloseServerOracle')
    private clickCloseServerOracleTodo() {
        return;
    }
    // 给父组件传值
    @Emit('handleAdjust')
    private handleAdjustTodo(goodsId: number) {
        return goodsId;
    }
    private clearAll() {
        if (this.selectProductList.length === 0) {
            return;
        }
        this.$confirm(`是否要清空所有选购的产品?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            this.handleClearAllTodo();
        }).catch(() => {
            // 取消
        });
    }
    private removeProduct(productItem: any) {
        this.handleProductTodo(productItem);
    }
    // 给父组件传值
    @Emit('handleProduct')
    private handleProductTodo(productItem: any) {
        return productItem;
    }
    // 给父组件传值
    @Emit('handleClearAll')
    private handleClearAllTodo() {
        return;
    }
}
