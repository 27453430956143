
import { debounce } from '@/utils/utils';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'AddUser',
})
export default class AddUser extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private existFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private serverId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private serverList!: any; // 接收父组件传过来的值
    private serverArr: any = [];
    private showDialog: boolean = false; // 弹窗显示与关闭
    private count: number = 1;
    private price: number = 0;
    private totalMoney: number = 0;
    private orderDetailTimeNumber: any = 0;
    private appTimeList: any = [];
    private serverEndTime: any = 0;
    @Watch('existFlag') // 监听父组件传过来的值
    private existFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.getUserNumberUnitPrice();
            const setInfo = localStorage.getItem('set_info_api');
            if (setInfo === null || setInfo === '' || setInfo === undefined) {
                this.getSetInfo();
            } else {
                this.getAppTimeList();
            }
        }
    }
    @Watch('serverList', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.serverArr = [].concat(value);
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    private getUserNumberUnitPrice() {
        const paramData: any = {};
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/user-number-unit-price')
        .then((res: any) => {
            this.price = res.price;
        });
    }
    private get getTotalMoney() {
        return this.totalMoney ? this.totalMoney.toFixed(2) : '0.00';
    }
    private get priceToFix() {
        return this.price.toFixed(2);
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        this.$httpService.getData({}, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.getAppTimeList();
        });
    }
    // 组装时间列表
    private getAppTimeList() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置 用户数时间列表
        const appTimeNumberSetList = res.licenseTimeNumberSetList;

        this.appTimeList = [];
        if (appTimeNumberSetList.length === 0) {
            return;
        }
        appTimeNumberSetList.forEach((item: any) => {
            this.appTimeList.push(item);
        });
        // this.serverArr.forEach((item: any) => {
        //     if (item.expireDays > 0
        //         && Number(item.serverId) === Number(this.serverId)
        //         && item.endTime !== '3000-01-01 00:00:00') {
        //         this.appTimeList.unshift({
        //             name: "与云服务器同时到期",
        //             number: -1,
        //         });
        //         this.serverEndTime = item.expireDays;
        //     }
        // });
        if (this.appTimeList.length > 0) {
            this.orderDetailTimeNumber = this.appTimeList[0].number;
        }
        this.getPrice();
    }
    private getPrice() {
        const paramData: any = {};
        paramData.goodsList	= this.assembleGoodsList();
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.totalMoney = res.totalMoney;
        });
    }
    private handleChangeInputNumber() {
        if (this.orderDetailTimeNumber === undefined) {
            this.orderDetailTimeNumber = 1;
        }
        debounce(() => {
            this.getPrice();
        }, 1000)();
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            if (this.count === undefined) {
                this.$message.success('请输入有效的数量');
                return;
            }
            const goodsList: any = this.assembleGoodsList();
            this.$router.push(`/company/orderConfirm?goodsData=${JSON.stringify(goodsList)}&userData=${this.userId}`)
            .catch((err) => err);
            this.closeTodo(num); // 给父组件传值
        } else {
            this.closeTodo(num); // 给父组件传值
        }
    }
    private assembleGoodsList(): any {
        const goodsList = [];
        const itemData: any = {
            goodsId: 19,
            orderDetailNumber: 1,
            orderDetailTimeNumber: this.orderDetailTimeNumber,
            orderGoodsSceneType: 10,
            serverId: this.serverId,
            userNumber: this.count,
        };
        goodsList.push(itemData);
        return goodsList;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(1); // 给父组件传值
    }
    private afterClose() {
        this.count = 1;
        this.price = 0;
    }

}
