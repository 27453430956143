
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'ServiceDialog',
})
export default class ServiceDialog extends Vue {
    private itemTab: any = {
        isSelect: false,
    };
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private num: number = 1;
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        if (value) {
            this.itemTab.serverNum = 1;
        }
        this.showDialog = value;
    }
    @Watch('itemData') // 监听父组件传过来的值
    private itemDataFun(value: any) {
        this.itemTab = Object.assign({}, value);
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): object {
        if (num === 3) {
            return this.itemTab;
        } else {
            return {};
        }
    }
    private created() {
        // this.itemTab = Object.assign({}, this.itemData);
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    private handleChange(value: number) {
        // console.log(value);
        if (this.itemTab.serverNum === undefined) {
            this.itemTab.serverNum = this.itemTab.minimumQuantity;
        }
    }

}
