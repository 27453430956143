
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: "NoCertification",
})
export default class NoCertification extends Vue {
    // 去认证
    private openCert() {
        this.$router.push({ path: "/company/Certification",
        query: { isBack: '0', isCert: '1' }}).catch((err) => err);
    }
}
