
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'ErrorHttpDialog',
})
export default class ErrorHttpDialog extends Vue {
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: String,
        default: null,
    }) private errorMessage!: string;
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeMsgTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeMsgTodo(num: number): number {
        return num;
    }
}
