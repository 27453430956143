
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';
@Component({
    name: 'ChooseBuyItem',
})
export default class ChooseBuyItem extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private priceNum: number = 0;
    private priceUnit: string = '/人天';
    private discountTitle = '';
    private itemTab: any = {
        text: '',
    };
    @Prop({
        type: Object,
        default: {},
    }) private itemData!: any; // 接收父组件传过来的值
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private itemDataFun(value: any) {
        this.itemTab = Object.assign({}, value);
        const buyTypeArr: any = [];
        this.itemTab.priceItems.forEach( (item: any) => {
            if (item.type === 2) {
                buyTypeArr.push({
                    buyType: 1,
                    unit: '/月',
                    price: item.price,
                });
            } else if (item.type === 3) {
                buyTypeArr.push({
                    buyType: 2,
                    unit: '/年',
                    price: item.price,
                });
            } else if (item.type === 1) {
                buyTypeArr.push({
                    buyType: 3,
                    unit: '',
                    price: item.price,
                });
            } else if (item.type === 4) {
                buyTypeArr.push({
                    buyType: 4,
                    unit: '',
                    price: item.price,
                });
            }
        });
        if (buyTypeArr.length > 0) {
            buyTypeArr.sort((a: any, b: any) => a.buyType - b.buyType);
            this.priceNum = buyTypeArr[0].price.toFixed(2);
            this.priceUnit = buyTypeArr[0].unit;
        } else {
            this.priceNum = Number(Number(0).toFixed(2));
            this.priceUnit = '';
        }
        // 服务
        if (this.itemTab.goodsPriceType === RESOURCE_CONFIG.SERVICE_GOODSPRICETYPE) {
            this.priceNum = this.itemTab.price ? this.itemTab.price.toFixed(2) : Number(0).toFixed(2);
            this.priceUnit = '/人天';
            if (this.itemTab.pricingScheme === 1) {
                this.priceUnit = '';
            }
        }
        if (this.itemTab.discountList) {
            this.discountTitle = '';
            this.itemTab.discountList.forEach((item: any) => {
                if (item.month === 999) {
                    this.discountTitle += `永久购买${item.discount}折优惠<br />`;
                } else if (item.month >= 12) {
                    this.discountTitle += `${item.month / 12}年以上${item.discount}折优惠<br />`;
                } else {
                    this.discountTitle += `${item.month}月以上${item.discount}折优惠<br />`;
                }
            });
        }
    }
    private created() {
        // this.itemTab = Object.assign({}, this.itemData);
    }
    // 给父组件传值
    @Emit('clickBuyService')
    private clickBuyServiceTodo(): object {
        return this.itemTab;
    }
    private openService() {
        this.clickBuyServiceTodo();
    }
    // 给父组件传值
    @Emit('clickCancelApplication')
    private clickCancelApplicationTodo(): object {
        return this.itemTab;
    }
    private selectApplication() {
        if (this.itemTab.isMask) {
            return;
        }
        if (!this.itemTab.isSelect) {
            return;
        }
        this.clickCancelApplicationTodo();
    }
}
