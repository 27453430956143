
import { ElForm } from 'node_modules/element-ui/types/form';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'PhoneCheckDialog',
})
export default class PhoneCheckDialog extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private phoneCheckFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: Number,
        default: 0,
    }) private mcId!: number; // 接收父组件传过来的值
    @Prop(String) private phone!: number; // 接收父组件传过来的值

    private showDialog: boolean = false; // 弹窗显示与关闭    // 倒计时相关变量
    private codeFlag: boolean = false;
    private time: number = 60;
    private interVal: any = null;
    private form = {
        phone: '',
        phoneAbbr: '',
        regCode: '',
    };
    private rules: any = {
        regCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
            // { validator: this.sendCode, trigger: 'blur' },
        ],
    };
    private sendCode(rule: any, value: any, callback: any) {
        if (this.codeFlag) {
            callback();
        } else {
            callback(new Error('请先点击发送验证码'));
        }
    }

    @Watch('phoneCheckFlag') // 监听父组件传过来的值
    private phoneCheckFlagFun(value: boolean) {
        this.showDialog = value;
    }

    @Watch('phone') // 监听父组件传过来的值
    private phoneFun(value: string) {
        // const valLen = value.length;
        // this.form.phoneAbbr = value.substring(0, 3) + '****' + value.substring(valLen - 4, valLen);
        this.form.phone = value;
    }

    // 给父组件传值
    @Emit('closeHandle')
    private closeTodo(num: number): number {
        // 清除倒计时
        this.codeFlag = false;
        clearInterval(this.interVal);
        this.time = 60;
        this.resetForm('form');
        this.showDialog = false;
        return num;
    }
    private resetForm(formName: any) {
        const elForm = this.$refs[formName]! as ElForm;
        this.form.regCode = '';
        elForm.resetFields();
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            // 判断校验是否通过
            if (submitName !== undefined) {
                const formData = this.$refs[submitName]! as ElForm;
                formData.validate((valid) => {
                    if (valid) {
                        this.saveRequest(num);
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        } else {
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 确定提交
    private saveRequest(num: number) {
        const paramData: any = {};
        paramData.mcId = this.mcId;
        paramData.code = this.form.regCode;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/cloudServer/system/recovery/checkCode')
        .then((res: any) => {
            this.$message({
                message: '还原成功！',
                type: 'success',
            });
            this.closeTodo(num); // 给父组件传值
        });
    }
    // 弹窗关闭后清除所有数据
    private afterClose() {
        this.form.regCode = '';
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeTodo(1); // 给父组件传值
    }
    // 获得验证码
    private getCode() {
        const paramData: any = {};
        paramData.mcId = this.mcId;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/cloudServer/system/recovery/sendCode')
        .then((res: any) => {
            this.countDown();
        });
    }
    // 验证码倒计时
    private countDown() {
        this.time = 60;
        this.codeFlag = true;
        this.interVal = setInterval(() => {
            this.time --;
            if (this.time === -1) {
                this.codeFlag = false;
                clearInterval(this.interVal);
            }
        }, 1000);
    }
}
