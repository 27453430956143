
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import EditRemarks from "./dialog/EditRemarks.vue";
import EmptySelectDialog from "@/components/EmptySelectDialog.vue";

@Component({
    name: "CheckSysDetail",
    components: {
        ContentTitle,
        EditRemarks,
        EmptySelectDialog,
    },
})
export default class CheckSysDetail extends Vue {
    private contentTitle: string = "基本资料";
    private editRemarksFlag: boolean = false;
    private emptyFlag: boolean = false;
    private content: string = "";
    private checkUserNum() {
        this.$router.push({ path: "/company/resource/checkUserNum", query: { }}).catch((err) => err);
    }
    private checkDbType() {
        this.$router.push({ path: "/company/resource/checkDbType", query: { }}).catch((err) => err);
    }
    private checkPwd() {
        this.emptyFlag = true;
        this.content = "MC密码：Morewis123!";
    }
    private tabCloseMsg(num: number) {
        this.emptyFlag = false;
    }
    private editRemarks() {
        this.editRemarksFlag = true;
    }
    private editRemarksCloseMsg(num: number) {
        this.editRemarksFlag = false;
    }
    private propGoBlack() {
        this.$router.go(-1);
    }
}
