
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { RESOURCE_CONFIG } from '@/utils/global';
@Component({
    name: 'InitServerOracle',
})
export default class InitServerOracle extends Vue {
    private itemTab: any = {
        text: '',
    };
    private itemArr: any = [];
    @Prop({
        type: Array,
        default: () => [],
    }) private selectProductList!: any; // 接收父组件传过来的值
    @Prop({
        type: Object,
        default: {},
    }) private allProductTab!: any; // 接收父组件传过来的值
    @Watch('allProductTab', {immediate: true, deep: true}) // 监听父组件传过来的值
    private allProductTabFun(value: any) {
        this.itemTab = Object.assign({}, value);
    }
    @Watch('selectProductList', {immediate: true, deep: true}) // 监听父组件传过来的值
    private selectProductListFun(value: any) {
        if (value.length > 0) {
            this.itemArr = [];
            value.forEach( (item: any) => {
                if (item.goodsId === RESOURCE_CONFIG.SERVER_GOODSID) {
                    const timeStr = (item.orderDetailTimeNumber >= 12)
                        ? item.orderDetailTimeNumber / 12 + '年' : item.orderDetailTimeNumber % 12 + '个月';
                    const selectedFourAppLen = value.filter((el: any) => el.mcVersion === '4.0').length;
                    this.itemArr.push({
                        goodsId: item.goodsId,
                        name: '云服务器',
                        title: selectedFourAppLen > 0
                        ? '【每增加一个应用，需增配1vCpu/4G内存/10G硬盘】'
                        : '【每增加一个应用，需增配1vCpu/1.5G内存/15G硬盘】',
                        cpu: item.cpu,
                        memory: item.memory,
                        harddisk: item.harddisk,
                        orderDetailTimeNumber: timeStr,
                        orderDetailNumber: item.orderDetailNumber,
                        price: this.itemTab.serverMoney.toFixed(2),
                    });
                } else if (item.goodsId === RESOURCE_CONFIG.ORACLE_GOODSID) {
                    const timeStr = (item.orderDetailTimeNumber >= 12)
                        ? item.orderDetailTimeNumber / 12 + '年' : item.orderDetailTimeNumber % 12 + '个月';
                    this.itemArr.push({
                        goodsId: item.goodsId,
                        name: 'Oracle数据库',
                        title: '【每增加一个应用，需增配一个数据库】',
                        cpu: item.cpu,
                        memory: item.memory,
                        harddisk: item.harddisk,
                        orderDetailTimeNumber: timeStr,
                        orderDetailNumber: item.orderDetailNumber,
                        price: this.itemTab.dbMoney.toFixed(2),
                    });
                }
                if (this.itemArr.length > 0) {
                    this.itemArr.sort((a: any, b: any) => a.goodsId - b.goodsId);
                }
            });
        }
    }
    // 给父组件传值
    @Emit('clickCloseServerOracle')
    private clickCloseServerOracleTodo() {
        return;
    }
    // 给父组件传值
    @Emit('handleAdjust')
    private handleAdjustTodo(goodsId: number) {
        return goodsId;
    }
}
