
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { moneyFormat } from '@/utils/utils';
import { RESOURCE_CONFIG } from '@/utils/global';

@Component({
    name: 'ResetUserNumberDialog',
})
export default class ResetUserNumberDialog extends Vue {
    private itemTab: any = {
        isSelect: false,
    };
    private goodsList: any = [];
    // 服务器的授权用户数
    private userNumber: number = 0;
    private userNumberSetList: any = [];
    private orderDetailTimeNumber: any = 0;
    // 服务器价格
    private serverTab: any = {
        totalMoney: 0,
        payMoney: 0,
    };
    private showDialog: boolean = false; // 弹窗显示与关闭
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: Array,
        default: [],
    }) private itemData!: any; // 接收父组件传过来的值
    @Prop({
        type: Boolean,
        default: false,
    }) private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    @Watch('itemData', {immediate: true, deep: true}) // 监听父组件传过来的值
    private async itemDataFun(value: any) {
        this.goodsList = [].concat(value);
        this.goodsList.filter((item: any) => {
            if (item.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID) {
                this.userNumber = item.userNumber;
                this.orderDetailTimeNumber = item.orderDetailTimeNumber;
                const setInfo = localStorage.getItem('set_info_api');
                if (setInfo === null || setInfo === '' || setInfo === undefined) {
                    this.getSetInfo();
                } else {
                    this.init();
                }
                this.startGetPrice();
            }
        });
    }
    private created() {
        // ...
    }
    private getSetInfo() {
        // 得到系统商品相关的配置
        const paramData: any = {};
        paramData.preventRepeat = 'resetUserNumber';
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/goods/set-info')
        .then((res: any) => {
            localStorage.setItem('set_info_api', JSON.stringify(res));
            this.init();
        });
    }
    private async init() {
        if (localStorage.getItem('set_info_api') === '' || localStorage.getItem('set_info_api') === undefined) {
            this.$message.error('数据错误');
            return;
        }
        const res = JSON.parse(localStorage.getItem('set_info_api') + '');
        // 配置云用户数
        this.userNumberSetList = [];
        res.userLimitList.filter((item: any) => {
            this.userNumberSetList.push(item);
        });
    }
    private getPrice() {
        this.startGetPrice();
    }
    private startGetPrice() {
        const paramData: any = {};
        paramData.goodsList	= [];
        const userNumberObj = {
            goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
            appName: '云用户数',
            orderDetailNumber: 1, // 选中商品数量(固定不变的)
            orderDetailTimeNumber: this.orderDetailTimeNumber, // 时长
            orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
            userNumber: this.userNumber,
        };
        paramData.goodsList.push(userNumberObj);
        if (this.userId !== '') {
            paramData.userList = unescape(this.userId).split(',');
        }
        paramData.userCheck = false;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/goods/price')
        .then((res: any) => {
            this.serverTab.payMoney = res.payMoney;
            this.serverTab.totalMoney = res.totalMoney;
        });
    }
    //  确定按钮
    private closeDialog(num: number, submitName?: string) {
        if (num === 3) {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): any {
        if (num === 3) {
            const userNumberObj = {
                goodsId: RESOURCE_CONFIG.USERNUMBER_GOODSID, // 选中商品传给后端的值
                appName: '云用户数',
                orderDetailNumber: 1, // 选中商品数量(固定不变的)
                orderDetailTimeNumber: this.orderDetailTimeNumber, // 时长
                orderGoodsSceneType: RESOURCE_CONFIG.USERNUMBER_GOODSSCENETYPE,
                userNumber: this.userNumber,
            };
            return userNumberObj;
        } else {
            return {};
        }
    }
    // 金额格式化
    private moneyFormat(value: any) {
        return moneyFormat(value, 2);
    }
}
