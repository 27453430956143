
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'PwdManage',
})
export default class PwdManage extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    }) private existFlag!: boolean; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private userId!: string; // 接收父组件传过来的值
    @Prop({
        type: String,
        default: '',
    }) private serverId!: string; // 接收父组件传过来的值
    private showDialog: boolean = false; // 弹窗显示与关闭
    private password: string = '';
    @Watch('existFlag') // 监听父组件传过来的值
    private existFlagFun(value: boolean) {
        this.showDialog = value;
        if (value) {
            this.getPassword();
        }
    }
    // 右上角的关闭按钮
    private beforeClose() {
        this.closeHandleTodo(0); // 给父组件传值
    }
    // 给父组件传值
    @Emit('closeHandle')
    private closeHandleTodo(num: number): number {
        this.showDialog = false;
        return num;
    }
    private getPassword() {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server/password')
        .then((res: any) => {
            this.password = res;
        });
    }
    private handleResetPassWord() {
        const msg = `请确定是否对该服务器重置密码？`;
        this.$confirm(msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            const paramData: any = {};
            paramData.serverId = this.serverId;
            paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
            this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server/password/reset')
            .then((res: any) => {
                this.$message.success('密码重置成功！');
                this.password = res.password;
            });
        }).catch(() => {
            // 取消
        });
    }
    private vpnGuide() {
        window.open('https://ntalk.morewis.com/web/article/0028e50c-fe01-484f-b3e1-db0c51226821');
    }
}
