
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "WaitOpen",
    components: {
        ContentTitle,
    },
})
export default class WaitOpen extends Vue {
    @Prop(Object) private paramServer?: any;
    private resData: any = [
        {
            name: 'CPU',
            dec: '-',
        },
        {
            name: '内存',
            dec: '-',
        },
        {
            name: '硬盘',
            dec: '-',
        },
        {
            name: '预计起始时间',
            dec: '-',
        },
        {
            name: '预计结束时间',
            dec: '-',
        },
    ];
    @Watch('paramServer')
    private paramServerWatch(val: object) {
        this.$nextTick(() => {
            this.setParam();
        });
    }
    private mounted() {
        this.$nextTick(() => {
            this.setParam();
        });
    }
    private setParam() {
        this.resData[0].dec = this.paramServer.serverCpu ? this.paramServer.serverCpu + 'vCpu' : '-';
        this.resData[1].dec = this.paramServer.serverMemory ? this.paramServer.serverMemory + 'G' : '-';
        this.resData[2].dec = this.paramServer.serverHarddisk ? this.paramServer.serverHarddisk + 'G' : '-';
    }
}
